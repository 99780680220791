import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ICAL: any;

@Component({
  selector: 'app-edit-reserva',
  templateUrl: './edit-reserva.component.html'
})
export class EditReservaComponent implements OnInit {
  public title:string = 'Nueva reserva';
  public data:any = {
    id: '',
    ninos: 0
  };
  public aforo:any = [];
  public fechas_bloqueadas = [];
  public fechas_bloqueadas_booking = [];
  public fechas_bloqueadas_airbnb= [];
  public mesesAforo:any = [];
  public diaSelecionado = 'No establecida';
  
  public estados = [];
  public eventos = [];
  public evento:any = {};
  public params_alo:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public tarifas = [];
  public pagos = [];
  public dataitem:any = {};
  public noches:number = 1;
  public estados_pago = [];
  public formas_pago = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nueva reserva';
    } else {
      this.title = 'Reserva #'+this.data.referencia;
      this.LoadAforo();
      this.diaSelecionado = this.data.fecha;
      this.GetEventoEditReserva(this.data.id_evento);
      // console.log(this.data);
    }
    if (this.data.noches) this.noches = this.data.noches;
    this.LoadFechasBloqueadas();
    if (this.data.id && this.data.id != '') {
      if (this.data.fecha_llegada && this.data.fecha_salida) {
        $('#txtfechallegadasalida').val(moment(this.data.fecha_llegada).format('DD/MM/YYYY') + ' - ' + moment(this.data.fecha_salida).format('DD/MM/YYYY'));
        $('#txtfechallegadasalida').data('daterangepicker').setStartDate(moment(this.data.fecha_llegada));
        $('#txtfechallegadasalida').data('daterangepicker').setEndDate(moment(this.data.fecha_salida));
      }
    }
    
    if (this.globals.GetData()) this.estados = this.globals.GetData('estados_reserva');
    this.globals.Api('/eve-estado-reserva').subscribe(data => {
      if (!data || !data.length) {
        this.estados = [];
        this.globals.SaveData([], 'estados_reserva');
        return;
      }
      this.estados = data;
      if (!this.data.id_estado && this.estados.length) this.data.id_estado = this.estados[0].id;
      this.globals.SaveData(this.estados, 'estados_reserva');
    });
    if (this.globals.GetData()) this.estados_pago = this.globals.GetData('estados_pago');
    this.globals.Api('/eve-estado-pago').subscribe(data => {
      if (!data || !data.length) {
        this.estados_pago = [];
        this.globals.SaveData([], 'estados_pago');
        return;
      }
      this.estados_pago = data;
      this.globals.SaveData(this.estados_pago, 'estados_pago');
    });
    if (this.globals.GetData()) this.formas_pago = this.globals.GetData('formas_pago');
    this.globals.Api('/eve-forma-pago').subscribe(data => {
      if (!data || !data.length) {
        this.formas_pago = [];
        this.globals.SaveData([], 'formas_pago');
        return;
      }
      this.formas_pago = data;
      if (!this.data.cli_tipo_pago || this.data.cli_tipo_pago == '') {
        this.data.cli_tipo_pago = this.formas_pago.find(el => el.pordefecto == 1).slug;
      }
      this.globals.SaveData(this.formas_pago, 'formas_pago');
    });
  }
  LoadFechasBloqueadas() {
    function getDates(startDate, stopDate) {
      let dateArray = [];
      let currentDate = new Date(startDate);
      stopDate = new Date(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dateArray;
    }
    if (this.data.id_evento && this.data.id_evento != '') {
      this.globals.Api('/eve-eventos', {id: this.data.id_evento, id_reserva: this.data.id}).subscribe(data => {
        if (!data || !data.length) return;
        data = data[0];
        this.fechas_bloqueadas = [];
        if (data.fechas_bloqueadas) {
          for (let item of data.fechas_bloqueadas) {
            if (!item || !item.fecha_format2) continue;
            this.fechas_bloqueadas.push(item.fecha_format2);
          }
        }
        if (data.fechas_reservadas) {
          for (let item of data.fechas_reservadas) {
            if (!item || !item.fecha_llegada_format2) continue;
            let dates = getDates(item.fecha_llegada_format2, item.fecha_salida_format2);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas.push(fecha);
            }
          }
        }
      });
      this.globals.Api('/get-ics/'+this.data.id_evento).subscribe(data => {
        if (!data) return;
        this.fechas_bloqueadas_booking = [];
        if (data.ics_booking && data.ics_booking != '') {
          let jcalData = ICAL.parse(data.ics_booking);
          let vcalendar = new ICAL.Component(jcalData);
          let vevents = vcalendar.getAllSubcomponents("vevent");
          for (let event of vevents) {
            let uid = event.getFirstPropertyValue('uid');
            let start = event.getFirstPropertyValue('dtstart');
            let end = event.getFirstPropertyValue('dtend');
            start = moment(new Date(start.year, start.month-1, start.day)).format('YYYY-MM-DD');
            end = moment(new Date(end.year, end.month-1, end.day)).format('YYYY-MM-DD');
            let dates = getDates(start, end);
            // console.log(uid);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas_booking.push(fecha);
            }
          }
        }
        this.fechas_bloqueadas_airbnb = [];
        if (data.ics_airbnb && data.ics_airbnb != '') {
          let jcalData = ICAL.parse(data.ics_airbnb);
          let vcalendar = new ICAL.Component(jcalData);
          let vevents = vcalendar.getAllSubcomponents("vevent");
          for (let event of vevents) {
            let uid = event.getFirstPropertyValue('uid');
            let start = event.getFirstPropertyValue('dtstart');
            let end = event.getFirstPropertyValue('dtend');
            start = moment(new Date(start.year, start.month-1, start.day)).format('YYYY-MM-DD');
            end = moment(new Date(end.year, end.month-1, end.day)).format('YYYY-MM-DD');
            let dates = getDates(start, end);
            // console.log(uid);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas_airbnb.push(fecha);
            }
          }
        }
        this.appref.tick();
        $('#calendario-cierres').datepicker('update');
      });
    }

    $('.rangepicker').daterangepicker({
      alwaysShowCalendars: true,
      opens: 'left',
      autoUpdateInput: false,
      locale: {
        "format": "DD/MM/YYYY",
        "separator": " - ",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancel",
        "fromLabel": "Desde",
        "toLabel": "Hasta",
        "customRangeLabel": "Personalizado",
        "weekLabel": "S",
        "daysOfWeek": [
            "Do",
            "Lu",
            "Ma",
            "Mi",
            "Ju",
            "Vi",
            "Sa"
        ],
        "monthNames": [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        "firstDay": 1
      },
      isInvalidDate: ele => {
        let fecha = moment(ele._d).format('YYYY-MM-DD');
        // if (this.fechas_bloqueadas.indexOf(fecha) != -1) {
        //   return true;
        // }
        // if (this.fechas_bloqueadas_booking.indexOf(fecha) != -1
        //   && this.fechas_bloqueadas_airbnb.indexOf(fecha) != -1) {
        //   return true;
        // }
        // if (this.fechas_bloqueadas_booking.indexOf(fecha) != -1) {
        //   return true;
        // }
        // if (this.fechas_bloqueadas_airbnb.indexOf(fecha) != -1) {
        //   return true;
        // }
        return false;
      }
    }).on('apply.daterangepicker', (ev, picker) => {
      let start = moment(picker.startDate.format('YYYY-MM-DD'));
      let end   = moment(picker.endDate.format('YYYY-MM-DD'));
      let diff = end.diff(start, 'days');
      let clearInput = false;
      for (let fecha of this.fechas_bloqueadas) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      for (let fecha of this.fechas_bloqueadas_booking) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      for (let fecha of this.fechas_bloqueadas_airbnb) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      if (clearInput) return false;
      $('#txtfechallegadasalida').val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
      this.data.fecha_llegada = picker.startDate.format('YYYY-MM-DD');
      this.data.fecha_salida = picker.endDate.format('YYYY-MM-DD');
      this.noches = diff;
      this.CalcularTotal();
    }).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  AddEvento() {
    $('#modal-seleccionar-alojamiento').modal('show');
    $('#modal-seleccionar-alojamiento .modal-title').html('Seleccionar actividad');
    this.CargarGridAlo();
  }
  GetEvento(){
    this.globals.Api('/eve-eventos',{id: this.data.id}).subscribe(data => {
      if (!data || !data.length) {
        this.eventos = [];return;
      }
      this.globals.SaveData(data);
      this.eventos = data;
      this.appref.tick();
      //if (!paginationload) return;
      // this.globals.Api('/eve-eventos/total', this.params_alo).subscribe(data => {
      //   if (!data || !data.length) return;
      //   this.params_alo.total = data[0].total;
      //   this.params_alo.pages = Math.ceil(this.params_alo.total / this.params_alo.page_size);
      // });
    });
  }
  GetEventoEditReserva(id_evento){
    this.globals.Api('/eve-eventos',{id: id_evento}).subscribe(data => {
      if (!data || !data.length) {
        this.eventos = [];return;
      }
      this.eventos = data;
      this.appref.tick();
      //if (!paginationload) return;
      // this.globals.Api('/eve-eventos/total', this.params_alo).subscribe(data => {
      //   if (!data || !data.length) return;
      //   this.params_alo.total = data[0].total;
      //   this.params_alo.pages = Math.ceil(this.params_alo.total / this.params_alo.page_size);
      // });
      this.SeleccionarEventoEditar(data[0]);
    });
  }
  EditEvento(item, event, tipo) {
    let html = $(event.target).html().trim();
    if (html.indexOf('<br>') != -1) $(event.target).html(html.replace('<br>', ''));
    let value = $(event.target).html().trim();
    if (isNaN(value)) value = null;
    if (tipo == 'adultos') {
      if (!value || value == '') return;
      this.data.adultos = value;
    }
    if (tipo == 'ninos') {
      this.data.ninos = value;
    }
    this.CalcularTotal();
    this.CargarEventosCalendario();
  }
  SeleccionarEvento(item:any) {
    $.tempalo = JSON.parse(JSON.stringify(item));
    if (item.tarifas && item.tarifas.length) this.tarifas = JSON.parse(JSON.stringify(item.tarifas));
    else this.tarifas = [];
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';

    this.evento = item;
    this.data.id_evento = item.id;
    this.data.nombre_evento = item.nombre;
    this.LoadAforo();
    $('#modal-seleccionar-alojamiento').modal('hide');
    $('#modal-seleccionar-tarifa').modal('show');
    $('#modal-seleccionar-tarifa .modal-title').html('Seleccionar tarifa');
  }
  SeleccionarEventoEditar(item:any) {
    $.tempalo = JSON.parse(JSON.stringify(item));
    if (item.tarifas && item.tarifas.length) this.tarifas = JSON.parse(JSON.stringify(item.tarifas));
    else this.tarifas = [];
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';

    this.evento = item;
    this.data.id_evento = item.id;
    this.data.nombre_evento = item.nombre;
    this.LoadAforo();
    // $('#modal-seleccionar-alojamiento').modal('hide');
    // $('#modal-seleccionar-tarifa').modal('show');
    // $('#modal-seleccionar-tarifa .modal-title').html('Seleccionar tarifa');
  }
  LoadAforo(){
    this.globals.Api('/eve-eventos-aforo',{'id': this.data.id_evento, 'oculto': true}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.aforo = data;
      var month = '';
      this.aforo.forEach(item =>{
        item.percentage = (100 * item.aforo_restante) / item.aforo_total;
        var dt = new Date(item.fecha);
        var monthYear:any = {};
        item.mes = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.month = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.year = item.anio = dt.getFullYear();
        if(month != monthYear.month){
          month = monthYear.month;
          this.mesesAforo.push(monthYear);
        }
      });
      this.CargarCalendario();
    });
  }
  SeleccionarTarifa(item:any) {
    if (
      this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.data.ninos == '') this.data.ninos = 0;
    if (!this.data.eventos) this.data.eventos = [];
    let max = 0;
    this.data.eventos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    max = max+1;
    this.data.eventos.push({
      id_reserva: '',
      id: max,
      id_alojamiento: $.tempalo.id,
      id_tarifa: item.id,
      eve_nombre: $.tempalo.nombre,
      eve_tarifa: 'Tarifa '+item.temporada,
      adultos: this.data.adultos,
      ninos: this.data.ninos,
      precio: item.precio
    });
    this.CalcularTotal();
    $('#modal-seleccionar-tarifa').modal('hide');
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';
  }
  AnadirEve() {
    if (
      this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    this.CalcularTotal();
    this.CargarEventosCalendario();
    $('#modal-seleccionar-tarifa').modal('hide');
  }
  CalcularTotal() {
    // if (!this.data.eventos || !this.data.eventos.length) return;
    let total:number = this.evento.precio * this.data.adultos;
    total = total + (this.evento.precio_ninios * this.data.ninos);
    // for (let item of this.data.eventos) {
    //   item.subtotal = parseFloat(item.precio)*this.noches
    //   if (item.subtotal && !isNaN(item.subtotal)) total += item.subtotal;
    // }
    // let max = 0;
    // this.data.eventos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    // max = max+1;
    this.data.iva = 10;
    this.data.base_imponible = total / 1.10;
    this.data.total = total;
    this.data.eventos = [];
    console.warn('Precio evento:' + this.evento);
    console.warn('Adultos:' + this.data.adultos);
    console.warn('Total:' + total);
    this.data.eventos.push({
      id_reserva: '',
      id: 1,
      id_evento: this.data.id_evento,
      id_tarifa: null,
      eve_nombre: this.data.nombre_evento,
      eve_tarifa: this.data.total,
      adultos: this.data.adultos,
      ninos: this.data.ninos,
      precio: this.data.precio
    });
    $('.nav-tabs li').click(function(){
      $('.nav-tabs li').removeClass('active');
      $('#content-meses>.tab-pane').removeClass('active');
      $('#'+$(this).attr('class')).addClass('active');
      $(this).addClass('active');
    });
    $('.content-days').click(function(){
      $('.content-days').removeClass('checked');
      $(this).addClass('checked');
    });
    //this.appref.tick();
    // this.globals.Api('/pub-total-fechas', this.data).subscribe(data => {
    //   if (!data || !data.total) {
    //     this.data.eventos = [];
    //     this.data.eventos.push({
    //       id_reserva: '',
    //       id: 1,
    //       alo_nombre: 'Seleccione fecha llegada y salida',
    //       alo_tarifa: 'Seleccione fecha llegada y salida',
    //     });
    //     return;
    //   }
    //   let total = parseFloat(data.total);
      
    // })
  }
  CalcularTotalAlojamiento() {
    // if (!this.data.eventos || !this.data.eventos.length) return;
    let total:number = 0;
    // for (let item of this.data.eventos) {
    //   item.subtotal = parseFloat(item.precio)*this.noches
    //   if (item.subtotal && !isNaN(item.subtotal)) total += item.subtotal;
    // }
    // let max = 0;
    // this.data.eventos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    // max = max+1;
    this.globals.Api('/pub-total-fechas', this.data).subscribe(data => {
      if (!data || !data.total) {
        this.data.eventos = [];
        this.data.eventos.push({
          id_reserva: '',
          id: 1,
          eve_nombre: 'Seleccione fecha llegada y salida',
          eve_tarifa: 'Seleccione fecha llegada y salida',
        });
        return;
      }
      let total = parseFloat(data.total);
      this.data.iva = 10;
      this.data.base_imponible = total / 1.21;
      this.data.total = total;
      this.data.eventos = [];
      this.data.eventos.push({
        id_reserva: '',
        id: 1,
        id_evento: this.data.id_evento,
        id_tarifa: null,
        eve_nombre: this.data.nombre_evento,
        eve_tarifa: 'Tarifa '+data.precio_noche+' / noche',
        adultos: this.data.adultos,
        ninos: this.data.ninos,
        precio: data.precio
      });
      this.appref.tick();
    })
  }
  DelEvento(item:any) {
    swal({
      title: "¿Borrar evento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.data.eventos.splice(this.data.eventos.findIndex(el => el.id == item.id), 1);
      this.data.base_imponible = 0;
      this.data.total = 0;
      $('#calendar').fullCalendar('removeEvents');
      this.appref.tick();
    });
  }

  CargarGridAlo(paginationload:boolean = true) {
    if (this.globals.GetData()) this.eventos = this.globals.GetData();
    this.globals.Api('/eve-eventos').subscribe(data => {
      if (!data || !data.length) {
        this.eventos = [];return;
      }
      this.globals.SaveData(data);
      this.eventos = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/eve-eventos/total', this.params_alo).subscribe(data => {
        if (!data || !data.length) return;
        this.params_alo.total = data[0].total;
        this.params_alo.pages = Math.ceil(this.params_alo.total / this.params_alo.page_size);
      });
    });
  }
  PagingAlo(dir, table = '') {
    if (dir == 'prev') {
      this.params_alo.page_index -= 1;
    }
    if (dir == 'next') {
      this.params_alo.page_index += 1;
    }
    this.CargarGridAlo(false);
  }
  BuscarAlo() {
    this.params_alo.page_index = 1;
    this.CargarGridAlo();
  }

  AddPago() {
    $('#modal-crear-pago').modal('show');
    $('#modal-crear-pago .modal-title').html('Nuevo pago');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    if (this.estados_pago.length) this.dataitem.id_estado_pago = this.estados_pago[0].id;
    if (this.formas_pago.length) this.dataitem.id_forma_pago = this.formas_pago[0].id;
    this.dataitem.tipo = 'manual';
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
    setTimeout(() => {
      this.appref.tick();
      $('#txtfechapago').datepicker('setDate', moment().format('DD/MM/YYYY'));
    }, 200);
  }
  EditPago(item:any) {
    $('#modal-crear-pago').modal('show');
    $('#modal-crear-pago .modal-title').html('Editar pago');
    this.dataitem = JSON.parse(JSON.stringify(item));
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
    setTimeout(() => {
      this.appref.tick();
      if (this.dataitem.fecha_pago) $('#txtfechapago').datepicker('setDate', this.data.fecha_pago);
    }, 200);
  }
  DelPago(item:any) {
    swal({
      title: "¿Borrar pago?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-del-pago', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.pagos.splice(this.data.pagos.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  GuardarPago() {
    if (
      $('#txtfechapago').val().trim() == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (
      !this.dataitem.id_estado_pago
      || this.dataitem.id_estado_pago == ''
      || !this.dataitem.id_forma_pago
      || this.dataitem.id_forma_pago == ''
      || !this.dataitem.tipo
      || this.dataitem.tipo == ''
      || !this.dataitem.total
      || this.dataitem.total == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.data.pagos) this.data.pagos = [];
    let max = 0;
    this.data.pagos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    max = max+1;
    this.data.pagos.push({
      id_reserva: '',
      id: max,
      id_estado_pago: this.dataitem.id_estado_pago,
      id_forma_pago: this.dataitem.id_forma_pago,
      tipo: this.dataitem.tipo,
      fecha_pago: moment($('#txtfechapago').datepicker('getDate')).format('YYYY-MM-DD'),
      fecha_pago_format: moment($('#txtfechapago').datepicker('getDate')).format('DD/MM/YYYY'),
      estado: $('#cmbestadopago option:selected').text(),
      forma_pago: $('#cmbformapago option:selected').text(),
      total: this.dataitem.total
    });
    this.appref.tick();
    $('#modal-crear-pago').modal('hide');
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/reservas')).then();
  }
  Guardar() {
    if (
      !this.diaSelecionado
      ) {
      swal('Seleccione fechas', 'Por favor, seleccione una fecha para continuar', 'error');return;
    }
    if (
      this.data.cli_nombre == ''
      || this.data.cli_apellidos == ''
      || this.data.cli_email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.cli_email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    if (
      !this.data.eventos
      || !this.data.eventos.length
      ) {
      swal('Seleccione alojamiento', 'Por favor, seleccione un alojamiento para continuar', 'error');return;
    }

    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    if (this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.data.fecha = this.diaSelecionado;
      this.data.impuestos = this.data.total - this.data.base_imponible;
      this.globals.Api('/eve-reservas-add', this.data).subscribe(data => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.data.fecha = this.diaSelecionado;
      this.globals.Api('/eve-reservas-edit', this.data).subscribe(data => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        // swal('Guardado', 'Datos guardados correctamente', 'success');
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        // this.LoadAforo();
        // this.CargarCalendario();
        // this.CargarEventosCalendario();
        // this.GetEventoEditReserva(this.data.id_evento);
        // this.appref.tick();
        this.router.navigateByUrl('/reservas');
      }, error => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: 580,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          // this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
          // this.params.fecha_fin = view.end.format('YYYY-MM-DD');
          // this.CargarReparaciones();
          that.CargarEventosCalendario();
        },
        eventRender: function(event, element) {
          element.find('.fc-title').append(`<div class="descripcion-evento">${event.description}</div>`); 
        },
        eventClick: function(info) {
          if (info.disabled) return;
          $('.fc-event').removeClass('checked');
          let totalPersonasReserva = parseInt(that.data.adultos) + parseInt(that.data.ninos);
          if(totalPersonasReserva > info.data.aforo_restante){
            that.diaSelecionado = 'No establecida';
            return;
          }
          if(info.data.ocultar == 1){
            that.diaSelecionado = 'No establecida';
            return;
          }
          that.diaSelecionado = info.data.fecha_format;
          $(this).addClass('checked');
          // if (info.tipo == 'documento') {
          //   if (info.data.popover) {
          //     $(this).popover('hide');
          //     info.data.popover = null;
          //     return;
          //   }
          //   // info.data.popover = true;
          //   // $(this).popover({ 
          //   //   html: true,
          //   //   title: `${info.data.titulo}${info.data.extension}`, 
          //   //   content: `
          //   //     <ul class="popover-btn">
          //   //       <li><a class="btn btn-primary bg-documentos" href="${info.data.documento}" target="_blank">Ver documento</a></li>
          //   //       <li><a class="btn btn-default" onclick="$.VerOportunidad('${info.data.id_oportunidad}')"><i class="fa fa-balance-scale"></i> Ir a la oportunidad</a></li>
          //   //     </ul>
          //   //   `,
          //   //   trigger: 'focus',
          //   //   placement: 'bottom',
          //   //   container: '.calendario'
          //   // }).popover('show');
          //   // window.open(info.data.documento, '_blank').focus();
          // }
          // if (info.tipo == 'cita') {
          //   info.data.title = 'Editar cita';
          //   if ($.editcitashow) $.editcitashow(info.data);
          //   $.editcitasave = (data:any) => {
          //     //that.CargarDatos();
          //     that.CargarEventosCalendario();
          //   };
          //   $.editcitadel = () => {
          //     //that.CargarDatos();
          //     that.CargarEventosCalendario();
          //   };
          // }
        },
        dayClick: function(date, allDay, jsEvent, view) {
          let params = {
            fecha: date,
            fecha_format: that.globals.DateString(new Date(date).toDateString())
          };
          if ($.editcitashow) $.editcitashow(params);
          $.editcitasave = (data:any) => {
            //that.CargarDatos();
            that.CargarEventosCalendario();
          };
        }
    });
  }
  CargarEventosCalendario() {
    $('#calendar').fullCalendar('removeEvents');
    if (this.aforo) {
      for (let item of this.aforo) {
        let start = new Date(item.fecha);
        let end = moment(start);
        let color = '#5da84e';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        if(item.percentage < 25){
          color = '#e5b437';
          cursor = 'pointer';
          if(item.percentage == 0){
            color = '#a30404';
            cursor = 'default';
            textColor = '#FFF';
          }
        }
        if(item.ocultar == 1){
          color = '#ebeff2';
          cursor = 'default';
          textColor = '#000';
          display = 'block';
        }
        let totalPersonasReserva = parseInt(this.data.adultos) + parseInt(this.data.ninos);
        if(totalPersonasReserva > item.aforo_restante){
          color = '#a30404'
          cursor = 'default';
          textColor = '#FFF';
        }
        let event = {
          id: item.id,
          title: '',
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"></i> Disponiblidad</b><br/><i class="fa fa-user"></i> ${item.aforo_restante}/${item.aforo_total} <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          color: color,
          textColor: textColor,
          disabled: false,
          tipo: 'documento'
        };
        $('#calendar').fullCalendar('renderEvent', event, true);
      }
    }
    $('#calendar').fullCalendar('refetchEvents');
    this.appref.tick();
    // let params:any = {};
    // params.fecha_inicio = $('#calendar').fullCalendar('getView').start.format('YYYY-MM-DD');
    // params.fecha_fin = $('#calendar').fullCalendar('getView').end.format('YYYY-MM-DD');
    // this.globals.Api('/datos-generales-calendario', params).subscribe(data => {
    //   $('#calendar').fullCalendar('removeEvents');
    //   if (!data) return;
      
    //   if (data.eventos) {
    //     for (let item of data.eventos) {
    //       let start = new Date(item.fecha);
    //       let end = moment(start).add(1, 'hours');
    //       let event = {
    //         id: item.id,
    //         title: '',
    //         description: `
    //           <div>
    //             <i class="fa fa-calendar-check-o"></i> ${item.hora}h
    //             <div><b>${item.titulo}</b></div>
    //           </div>
    //         `,
    //         start: start,
    //         end: end,
    //         data: item,
    //         color: '#3fc0ed',
    //         textColor: '#FFF',
    //         disabled: false,
    //         tipo: 'cita'
    //       };
    //       $('#calendar').fullCalendar('renderEvent', event, true);
    //     }
    //   }
    // }, error => {
    // });
  }
  SeleccionarDia(dia:any){
    this.diaSelecionado = dia;
  }

  ConfirmarAsistencia() {
    swal({
      title: "¿Confirmar asistencia?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/check-reserva-id', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      });
    });
  }
  Borrar() {
    swal({
      title: "¿Borrar reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-del', {
        id: this.data.id,
        fecha: this.data.fecha, 
        id_evento: this.data.id_evento, 
        adultos: this.data.adultos, 
        ninios: this.data.ninos, 
        niniosGratis: this.data.ninos_gratis, 
        sesion: this.data.sesion, 
        fecha_bd: this.data.fecha_bd}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      });
    });
  }
}
