import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let ICAL: any;
declare let Chart: any;
declare let toastr: any;

@Component({
  selector: 'app-backend-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  public data:any = {};
  public aforo = [];
  public mesesAforo:any = [];
  public historicoReservas:any = [];
  public actividades:any = [];
  public idCalendario:any;
  public primeraCargaCalendario = true;
  public sesiones:any = [];
  public aforoDia:any = [];
  public idUsuario:any;
  public dataReserva:any = {
    id_evento:    '',
    fecha:        '',
    sesion:       '',
    adultos:      0,
    ninios:       0,
    niniosGratis: 0,
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    precio:       0,
    tipoPago:     ''
  };
  public precioAdulto:any;
  public precioNinio:any;
  public precioCalculoAdulto:any = 0;
  public precioCalculoNinio:any = 0;
  public plazasDisponibles:any = 50;
  public rankingColaboradores:any;

  public contadorReservas:any = 0;
  public myChart:any;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    // this.idUsuario = this.globals.me.id_rol;
    switch(this.globals.me.id_rol){
      case 0:{
        this.loadDataAdmin();
        this.dataReserva.tipo = 'Admin';
      } break;
      case 1:{
        this.loadDataAdmin();
        this.dataReserva.tipo = 'Admin';
      } break;
      case 4:{
        this.dataReserva.id_colaborador = this.globals.me.id;
        this.dataReserva.tipo = 'Partner';
        this.loadDataColaborador();
      } break;
      default:{
      } break;
    }
  }

  loadDataAdmin(){
    this.globals.Api('/datos-generales').subscribe(data => {
      if (!data) return;
      this.data = data;
      this.CargarGrafica();
    });
    this.globals.Api('/ranking-colaboradores').subscribe(data => {
      if (!data) return;
      this.rankingColaboradores = data;
    });
    setInterval(() => {
      //toastr.warning(`Actualizando datos...`);
      this.globals.Api('/datos-generales').subscribe(data => {
        if (!data) return;
        this.data = data;
        this.CargarGrafica();
        this.loadHistoricoReservas();
        this.globals.Api('/ranking-colaboradores').subscribe(data => {
          if (!data) return;
          this.rankingColaboradores = data;
          setTimeout(() => {
            //toastr.success(`¡Datos actualziados!`);
          }, 2000);
        });
      });
    },120000);
    setTimeout(() => {
      this.idCalendario = 5;
      this.dataReserva.id_evento = this.idCalendario;
      this.LoadAforo();
    }, 100);
    this.loadHistoricoReservas();
    this.loadActividades();
    let that = this;
    setTimeout(() => {
      $('#selector-actividades-calendario').val('6');
      $('#selector-actividades-calendario').change(function(){
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.6)',
          imageResizeFactor: 0.5
        });
        that.idCalendario = $(this).val();
        that.LoadAforo();
      });
      $('#custom-menos-adulto').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.adultos--;
          if(that.dataReserva.adultos == 0){
            $(this).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-adulto').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.adultos++;
          $('#custom-menos-adulto').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-menos-ninio').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.ninios--;
          if(that.dataReserva.ninios == 0){
            $(this).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-ninio').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.ninios++;
          $('#custom-menos-ninio').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-menos-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.niniosGratis--;
          if(that.dataReserva.niniosGratis == 0){
            $(this).addClass('disabled-custom-input');
          }
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.niniosGratis++;
          $('#custom-menos-ninio-gratis').removeClass('disabled-custom-input');
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
    }, 1000);
  }
  loadDataColaborador(){
    this.globals.Api('/datos-generales').subscribe(data => {
      if (!data) return;
      this.data = data;
    });
    setTimeout(() => {
      this.idCalendario = 5;
      this.dataReserva.id_evento = this.idCalendario;
      this.LoadAforo();
      this.CargarGrafica();
    }, 100);
    this.loadContadorReservasColaborador();
    this.loadActividades();
    let that = this;
    setTimeout(() => {
      $('#selector-actividades-calendario').val('5');
      $('#selector-actividades-calendario').change(function(){
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.6)',
          imageResizeFactor: 0.5
        });
        that.idCalendario = $(this).val();
        that.LoadAforo();
      });
      $('#custom-menos-adulto').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.adultos--;
          if(that.dataReserva.adultos == 0){
            $(this).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-adulto').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.adultos++;
          $('#custom-menos-adulto').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-menos-ninio').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.ninios--;
          if(that.dataReserva.ninios == 0){
            $(this).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-ninio').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.ninios++;
          $('#custom-menos-ninio').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-menos-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.niniosGratis--;
          if(that.dataReserva.niniosGratis == 0){
            $(this).addClass('disabled-custom-input');
          }
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.niniosGratis++;
          $('#custom-menos-ninio-gratis').removeClass('disabled-custom-input');
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
    }, 1000);
  }
  //Checkeamos si hay aforos configurados
  LoadAforo(){
    this.globals.Api('/eve-eventos',{'id': this.idCalendario}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.sesiones = data[0].sesiones.split(',');
      this.sesiones.pop();
      this.precioAdulto = data[0].precio;
      this.precioNinio = data[0].precio_ninios;
    });
    this.globals.Api('/eve-eventos-aforo',{'id': this.idCalendario, 'oculto': true}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.aforo = data;
      var month = '';
      this.aforo.forEach(item =>{
        item.percentage = (100 * item.aforo_restante) / item.aforo_total;
        var dt = new Date(item.fecha);
        var monthYear:any = {};
        item.mes = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.month = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.year = item.anio = dt.getFullYear();
        if(month != monthYear.month){
          month = monthYear.month;
          this.mesesAforo.push(monthYear);
        }
      });
      if(this.primeraCargaCalendario){
        this.CargarCalendario();
        this.primeraCargaCalendario = false;
      }else{
        this.CargarEventosCalendario();
        $.LoadingOverlay("hide");
      }
    });
  }

  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 425;
    if($(window).width() <= 1366 && $(window).width() > 1025){
      heightCalendar = 380;
    }
    if($(window).width() <= 500){
      heightCalendar = 340;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          // this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
          // this.params.fecha_fin = view.end.format('YYYY-MM-DD');
          // this.CargarReparaciones();

          /*Aquí para cargar los datos al calendario*/
          that.CargarEventosCalendario();
          $('.fc-day-top').mouseenter(function(){
            $('.content-hover-aforo').remove();
            var fechaDiaHover = $(this).attr('data-date');
            var aforoDia = [];
            var bloqueado = true;
            that.aforo.forEach(element => {
              if(element.ocultar == 1){
                return;
              }
              if(element.fecha == fechaDiaHover){
                that.sesiones.forEach(element2 => {
                  if(element.sesion == element2){
                    aforoDia.push(element.aforo_restante);
                    bloqueado = false;
                  }
                });
              }
            });
            if(bloqueado) return;
            
            var htmlAforo = `<div class="content-hover-aforo display-flex style="padding-left: 10px; padding-right: 10px;">`;
            for(var i = 0; i < aforoDia.length; i++){
              htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+that.sesiones[i]+` <br/><span>`+aforoDia[i]+` plazas</span></div>`;
            }
            htmlAforo += `</div>`;
            $(this).append(htmlAforo);
            $('.fc-day-grid.fc-unselectable>div:first-child .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:first-child:hover').addClass('z-index-9999');
          });
          $('.fc-day-top').mouseleave(function(){
            $('.content-hover-aforo').remove();
            $('.fc-day-grid.fc-unselectable>div:first-child').removeClass('z-index-9999');
          });
        },
        eventRender: function(event, element) {
          //element.find('.fc-title').append(`<div class="descripcion-evento">${event.description}</div>`);
        },
        eventClick: function(info) {
          if (info.disabled) return;
          $('.fc-event').removeClass('checked');
          $(this).addClass('checked');
          // if (info.tipo == 'documento') {
          //   if (info.data.popover) {
          //     $(this).popover('hide');
          //     info.data.popover = null;
          //     return;
          //   }
          //   // info.data.popover = true;
          //   // $(this).popover({ 
          //   //   html: true,
          //   //   title: `${info.data.titulo}${info.data.extension}`, 
          //   //   content: `
          //   //     <ul class="popover-btn">
          //   //       <li><a class="btn btn-primary bg-documentos" href="${info.data.documento}" target="_blank">Ver documento</a></li>
          //   //       <li><a class="btn btn-default" onclick="$.VerOportunidad('${info.data.id_oportunidad}')"><i class="fa fa-balance-scale"></i> Ir a la oportunidad</a></li>
          //   //     </ul>
          //   //   `,
          //   //   trigger: 'focus',
          //   //   placement: 'bottom',
          //   //   container: '.calendario'
          //   // }).popover('show');
          //   // window.open(info.data.documento, '_blank').focus();
          // }
          // if (info.tipo == 'cita') {
          //   info.data.title = 'Editar cita';
          //   if ($.editcitashow) $.editcitashow(info.data);
          //   $.editcitasave = (data:any) => {
          //     //that.CargarDatos();
          //     that.CargarEventosCalendario();
          //   };
          //   $.editcitadel = () => {
          //     //that.CargarDatos();
          //     that.CargarEventosCalendario();
          //   };
          // }
        },
        dayClick: function(date, allDay, jsEvent, view) {
          let params = {
            fecha: date,
            fecha_format: that.globals.DateString(new Date(date).toDateString())
          };
          if ($.editcitashow) $.editcitashow(params);
          var today = new Date();
          var tempDate = new Date(date);
          if(today > tempDate){
            if(today != tempDate){

            }else{
              return;
            }
          }
          that.dataReserva.fecha = that.globals.DateStringEN(new Date(date).toDateString());
          var bloqueado = true;
          that.aforoDia = [];
          that.aforo.forEach(element => {
            if(element.ocultar == 1){
              return;
            }
            if(element.fecha == that.dataReserva.fecha){
              that.sesiones.forEach(element2 => {
                if(element.sesion == element2){
                  that.aforoDia.push({id: 'radio-reserva-'+element.sesion,sesion: element.sesion, aforo: element.aforo_restante});
                  bloqueado = false;
                }
              });
            }
          });
          if(bloqueado) return;
          $('#date-reservar').val(that.dataReserva.fecha);

          $('#modal-reservar-calendar').modal('show');
          setTimeout(() => {
            $('.radios-sesion').click(function(){
              // var textRadio = $(this).val().split('-');
              // that.dataReserva.sesion = textRadio[0];
              // // var plazas = textRadio[1].split(' '); 
              // that.plazasDisponibles = parseInt(textRadio[1]);
              // if(that.plazasDisponibles < (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
              //   that.dataReserva.adultos = 0;
              //   that.dataReserva.ninios = 0;
              //   that.dataReserva.niniosGratis = 0;
              //   that.precioCalculoAdulto = 0;
              //   that.precioCalculoNinio = 0;
              //   that.calcularPrecioModal();
              //   $('#custom-mas-adulto').removeClass('disabled-custom-input');
              //   $('#custom-mas-ninio').removeClass('disabled-custom-input');
              //   $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
              //   $('#custom-menos-adulto').addClass('disabled-custom-input');
              //   $('#custom-menos-ninio').addClass('disabled-custom-input');
              //   $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
              // }else{
              //   $('#custom-mas-adulto').removeClass('disabled-custom-input');
              //   $('#custom-mas-ninio').removeClass('disabled-custom-input');
              //   $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
              // }
              // if(that.plazasDisponibles == 0){
              //   $('#custom-mas-adulto').addClass('disabled-custom-input');
              //   $('#custom-mas-ninio').addClass('disabled-custom-input');
              //   $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
              //   $('#custom-menos-adulto').addClass('disabled-custom-input');
              //   $('#custom-menos-ninio').addClass('disabled-custom-input');
              //   $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
              // }
            })
          }, 100);
          // $.editcitasave = (data:any) => {
          //   //that.CargarDatos();
          //   //that.CargarEventosCalendario();
          // };
        }
    });
  }

  CargarEventosCalendario() {
    $('#calendar').fullCalendar('removeEvents');
    if (this.aforo) {
      let mismaFecha = false;
      let fechaTemp = '';
      let sumPorcentajeMismaFecha = 0;
      for (let item of this.aforo) {
        if(fechaTemp != item.fecha){
          fechaTemp = item.fecha;
          sumPorcentajeMismaFecha = item.percentage;
          mismaFecha = false;
        }else{
          mismaFecha = true;
          sumPorcentajeMismaFecha = (sumPorcentajeMismaFecha + item.percentage) / 2;
        }
        let start = new Date(item.fecha);
        let end = moment(start);
        let color = '#5da84e';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        let className = 'day-green';
        if(sumPorcentajeMismaFecha < 50){
          color = '#e5b437';
          cursor = 'pointer';
          className = 'day-yellow';
          if(sumPorcentajeMismaFecha < 25){
            color = '#a30404';
            cursor = 'default';
            textColor = '#FFF';
            className = 'day-red';
          }
        }
        if(item.ocultar == 1){
          color = '#ebeff2';
          cursor = 'default';
          textColor = '#000';
          display = 'block';
          className = 'day-gray';
        }
        // let totalPersonasReserva = parseInt(this.data.adultos) + parseInt(this.data.ninos);
        // if(totalPersonasReserva > item.aforo_restante){
        //   color = '#a30404'
        //   cursor = 'default';
        //   textColor = '#FFF';
        // }
        if(!mismaFecha){
          className = 'hide';
        }
        let event = {
          id: item.id,
          className: className,
          title: `${item.aforo_restante}/${item.aforo_total}`,
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"></i> Disponiblidad</b><br/><i class="fa fa-user"></i> ${item.aforo_restante}/${item.aforo_total} <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          //color: color,
          color: 'transparent',
          // textColor: textColor,
          textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        $('#calendar').fullCalendar('renderEvent', event, true);
      }
    }
    $('#calendar').fullCalendar('refetchEvents');
    this.appref.tick();
    // let params:any = {};
    // params.fecha_inicio = $('#calendar').fullCalendar('getView').start.format('YYYY-MM-DD');
    // params.fecha_fin = $('#calendar').fullCalendar('getView').end.format('YYYY-MM-DD');
    // this.globals.Api('/datos-generales-calendario', params).subscribe(data => {
    //   $('#calendar').fullCalendar('removeEvents');
    //   if (!data) return;
      
    //   if (data.eventos) {
    //     for (let item of data.eventos) {
    //       let start = new Date(item.fecha);
    //       let end = moment(start).add(1, 'hours');
    //       let event = {
    //         id: item.id,
    //         title: '',
    //         description: `
    //           <div>
    //             <i class="fa fa-calendar-check-o"></i> ${item.hora}h
    //             <div><b>${item.titulo}</b></div>
    //           </div>
    //         `,
    //         start: start,
    //         end: end,
    //         data: item,
    //         color: '#3fc0ed',
    //         textColor: '#FFF',
    //         disabled: false,
    //         tipo: 'cita'
    //       };
    //       $('#calendar').fullCalendar('renderEvent', event, true);
    //     }
    //   }
    // }, error => {
    // });
  }

  CargarGrafica(){
    const labels = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];
  
    const data = {
      labels: labels,
      datasets: [{
        label: 'Reservas',
        fill: false,
        backgroundColor: '#DB0033',
        // borderColor: 'rgb(255, 99, 132)',
        borderColor: '#707070',
        data: [
          this.data.total_reservas_enero,
          this.data.total_reservas_febrero,
          this.data.total_reservas_marzo,
          this.data.total_reservas_abril,
          this.data.total_reservas_mayo,
          this.data.total_reservas_junio,
          this.data.total_reservas_julio,
          this.data.total_reservas_agosto,
          this.data.total_reservas_septiembre,
          this.data.total_reservas_octubre,
          this.data.total_reservas_noviembre,
          this.data.total_reservas_diciembre],
        pointRadius: 8,
      }]
    };
  
    const config = {
      type: 'line',
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    };
    if(this.myChart){
      //this.myChart.chart(config);
      const data = {
        labels: labels,
        datasets: [{
          label: 'Reservas',
          fill: false,
          backgroundColor: '#DB0033',
          // borderColor: 'rgb(255, 99, 132)',
          borderColor: '#707070',
          data: [
            this.data.total_reservas_enero,
            this.data.total_reservas_febrero,
            this.data.total_reservas_marzo,
            this.data.total_reservas_abril,
            this.data.total_reservas_mayo,
            this.data.total_reservas_junio,
            this.data.total_reservas_julio,
            this.data.total_reservas_agosto,
            this.data.total_reservas_septiembre,
            this.data.total_reservas_octubre,
            this.data.total_reservas_noviembre,
            this.data.total_reservas_diciembre],
          pointRadius: 8,
        }]
      };
      this.myChart.data.datasets.forEach(element => {
        element.data.push(data);
      });
      this.myChart.update();
    }else{
      this.myChart = new Chart(
        document.getElementById('myChart'),
        config
      );
    }
  }
  loadHistoricoReservas(){
    this.globals.Api('/eve-reservas-actividad-historico').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar histórico', 'error');return;
      }
      this.historicoReservas = data;
    });
  }
  loadContadorReservasColaborador(){
    this.globals.Api('/eve-reservas-actividad-colaborador',{id: this.globals.me.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar histórico', 'error');return;
      }
      this.contadorReservas = data.contador;
    });
  }
  loadActividades(){
    this.globals.Api('/eve-eventos-dashboard').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar actividades', 'error');return;
      }
      this.actividades = data;
    });
  }
  ComprobarGuardarReserva(){
    if(
      this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00' ||
      this.dataReserva.sesion == null || this.dataReserva.sesion == '' ||
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''
    ){
      swal('Error', 'Rellena todos los datos', 'error');return;
      //setInterval
    }
    // let aforoCheck = (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis);
    // let that = this;
    // this.globals.Api('/comprobar-aforo',{idEvento: this.idCalendario, aforoCheck: aforoCheck, dia: this.dataReserva.fecha, sesion: this.dataReserva.sesion}).subscribe(data => {
    //   if (!data || data.error) {
    //     swal('Error', 'Error al validar el aforo', 'error');return;
    //   }
    //   if(data.results == 0){
    //     swal('Error', 'Error al validar el aforo, revísalo en el calendario', 'warning');return;
    //   }
    //   that.GuardarReserva();
    // });
    //Pasamos directamente sin validar ya que con lo de las butacas VIP no hace falta ya que al seleccionar la butaca carga directamente el aforo disponible
    this.GuardarReserva();
  }
  GuardarReserva(){
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    this.globals.Api('/eve-reservas-calendar-add',this.dataReserva).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');return;
      }
      swal('Guardado', '¡Reserva hecha con éxito!', 'success');
      $('#modal-reservar-calendar').modal('hide');
    });
  }
  AnteriorModal(){
    $('#content-paso-1').fadeIn();
    $('#content-paso-2').hide();
  }
  SiguienteModal(){
    if(this.idCalendario == 6 && (this.dataReserva.ninios > 0 || this.dataReserva.niniosGratis > 0)){
      swal('¡Atención!', 'Para las butacas VIP no se aceptan niños', 'warning');
      return;
    }
    var radiosSeleccion = $('.radios-sesion');
    var checkRadio = false;
    for(var i = 0; i < radiosSeleccion.length; i++){
      if($(radiosSeleccion[i]).is(':checked')){
        this.dataReserva.sesion = $(radiosSeleccion[i]).val();
        checkRadio = true;
      }
    }
    if(!checkRadio){
      swal('¡Atención!', 'Debe seleccionar una sesión', 'warning');
      return;
    }
    if(this.dataReserva.adultos == 0){
      swal('¡Atención!', 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }
    // let aforoCheck = (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis);
    // this.globals.Api('/comprobar-aforo',{idEvento: this.idCalendario, aforoCheck: aforoCheck, dia: this.dataReserva.fecha, sesion: this.dataReserva.sesion}).subscribe(data => {
    //   if (!data || data.error) {
    //     swal('Error', 'Error al validar el aforo', 'error');return;
    //   }
    //   if(data.results == 0){
    //     swal('Error', 'Error al validar el aforo, revísalo en el calendario', 'warning');return;
    //   }
    // });
    //Pasamos directamente sin validar ya que con lo de las butacas VIP no hace falta ya que al seleccionar la butaca carga directamente el aforo disponible
    $('#content-paso-1').hide();
    $('#content-paso-2').fadeIn();
  }
  calcularPrecioModal(){
    this.precioCalculoAdulto = this.dataReserva.adultos * this.precioAdulto;
    this.precioCalculoNinio = this.dataReserva.ninios * this.precioNinio;
    this.dataReserva.precio = this.precioCalculoAdulto + this.precioCalculoNinio;
  }
  SelectReservaProveedor(event){
    if($('#select-colaboradores-reserva-admin').val() != 'admin'){
      this.dataReserva.id_colaborador = $('#select-colaboradores-reserva-admin').val();
      console.log($('#select-colaboradores-reserva-admin').val());
      this.dataReserva.tipo = 'Partner';
    }else{
      this.dataReserva.id_colaborador = '';
      this.dataReserva.tipo = 'admin';
    }
  }
  setButaca(id_evento:any){
    this.dataReserva.adultos = 0;
    this.dataReserva.ninios = 0;
    this.dataReserva.niniosGratis = 0;
    $('#custom-menos-adulto').addClass('disabled-custom-input');
    $('#custom-menos-ninio').addClass('disabled-custom-input');
    $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
    this.calcularPrecioModal();
    this.idCalendario = id_evento;
    this.dataReserva.id_evento = this.idCalendario;
    this.globals.Api('/eve-eventos',{'id': this.idCalendario}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.sesiones = data[0].sesiones.split(',');
      this.sesiones.pop();
      console.log(this.sesiones);
      this.precioAdulto = data[0].precio;
      this.precioNinio = data[0].precio_ninios;
    });
    
    this.globals.Api('/eve-eventos-aforo',{'id': this.idCalendario, 'oculto': true, fecha: this.dataReserva.fecha}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      console.log(data);
      let sesionesButacaSelect = data;
      this.aforoDia = [];
      sesionesButacaSelect.forEach(sesion => {
        this.aforoDia.push({id: 'radio-reserva-'+sesion.sesion,sesion: sesion.sesion, aforo: sesion.aforo_restante});
      });
    });
    if(this.idCalendario == 6){
      $('#custom-mas-ninio').addClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
      $('#custom-mas-ninio').hide();
      $('#custom-mas-ninio-gratis').hide();
    }else{
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').show();
      $('#custom-mas-ninio-gratis').show();
    }
  }
  setSesion(sesionDisponibilidad:any){
    var textRadio = sesionDisponibilidad.split('-');
    this.dataReserva.sesion = textRadio[0];
    // var plazas = textRadio[1].split(' '); 
    this.plazasDisponibles = parseInt(textRadio[1]);
    if(this.plazasDisponibles <= (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis)){
      this.dataReserva.adultos = 0;
      this.dataReserva.ninios = 0;
      this.dataReserva.niniosGratis = 0;
      this.precioCalculoAdulto = 0;
      this.precioCalculoNinio = 0;
      this.calcularPrecioModal();
      $('#custom-mas-adulto').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
      $('#custom-menos-adulto').addClass('disabled-custom-input');
      $('#custom-menos-ninio').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
    }else{
      $('#custom-mas-adulto').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
    }
    if(this.plazasDisponibles == 0){
      $('#custom-mas-adulto').addClass('disabled-custom-input');
      $('#custom-mas-ninio').addClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
      $('#custom-menos-adulto').addClass('disabled-custom-input');
      $('#custom-menos-ninio').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
    }
    if(this.idCalendario == 6){
      $('#custom-mas-ninio').addClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
      $('#custom-mas-ninio').hide();
      $('#custom-mas-ninio-gratis').hide();
    }else{
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').show();
      $('#custom-mas-ninio-gratis').show();
    }
  }
}
