<div class="modal inmodal fade" id="modal-reserva" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
            <div class="pull-right m-r-30 m-t--30 mobile-hidden">
              <ng-container *ngIf="globals.me.id_rol != 3 && globals.me.id_rol != 4">
                <a (click)="Editar()" class="btn btn-primary btn-sm m-r-30"><i class="fa fa-pencil"></i> Editar</a>
                <a (click)="Borrar()" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i> Borrar</a>
              </ng-container>
              <ng-container *ngIf="globals.me.id_rol == 3 && data.puede_cancelar && data.id_estado != 4 && data.id_estado != 5">
                <small class="m-r-15">Puede cancelar hasta {{data.dias_cancelacion}} días de la fecha de llegada</small>
                <a (click)="CancelarReserva()" class="btn btn-danger btn-sm m-r-30"><i class="fa fa-times"></i> Cancelar reserva</a>
              </ng-container>
              <ng-container *ngIf="globals.me.id_rol == 3 && !data.puede_cancelar">
                <small>Esta reserva no permite cancelación actualmente</small>
              </ng-container>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <h2 class="m-b-20 font-13 title-border-bottom">DATOS DE CLIENTE</h2>
                <div>{{data.cli_nombre}} {{data.cli_apellidos}}</div>
                <div><span *ngIf="data.cli_empresa">{{data.cli_empresa}} </span>{{data.cli_nifcif}}</div>
                <div>{{data.cli_direccion}}, {{data.cli_codpostal}}</div>
                <div>{{data.cli_localidad}}, {{data.cli_provincia}}</div>
                <div>Email: <a href="mailto:{{data.cli_email}}">{{data.cli_email}}</a></div>
                <div>Teléfono: <a href="tel:{{data.cli_telefono}}">{{data.cli_telefono}}</a></div>
              </div>
              <div class="col-md-6 col-xs-12">
                <h2 class="m-b-20 font-13 title-border-bottom">DATOS DE LA RESERVA</h2>
                <div>Referencia: #{{data.referencia}}</div>
                <div>Estado: <b>{{data.estado}}</b></div>
                <div class="font-bold">{{data.alojamiento}}</div>
                <div>Precio: <b>{{data.tarifa}}</b></div>
                <div>Llegada: {{data.fecha_llegada_format}}</div>
                <div>Salida: {{data.fecha_salida_format}}</div>
                <div>Noches: {{data.noches}}</div>
                <div>Adultos: {{data.adultos}}</div>
                <div>Ni&ntilde;os: {{data.ninos}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div>Notas:<br>{{data.cli_notas}}</div>

              </div>
              <div class="col-md-6 col-xs-12">
                <h2 class="m-b-20 font-13 title-border-bottom">RESUMEN</h2>
                <div class="font-15">Base Imp.: <b>{{data.base_imponible_format}}€</b></div>
                <div class="font-15">IVA: <b>{{data.iva_format}}%</b></div>
                <div class="font-15">Importe Imp.: <b>{{data.impuestos_format}}€</b></div>
                <div class="font-18 font-bold text-primary">TOTAL: <b>{{data.total_format}}€</b></div>
              </div>
            </div>
            <div class="m-t-20 text-center desktop-hidden">
              <ng-container *ngIf="globals.me.id_rol != 3">
                <a (click)="Editar()" class="btn btn-primary btn-sm m-r-30"><i class="fa fa-pencil"></i> Editar</a>
                <a (click)="Borrar()" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i> Borrar</a>
              </ng-container>
              <ng-container *ngIf="globals.me.id_rol == 3 && data.puede_cancelar && data.id_estado != 4 && data.id_estado != 5">
                <small class="m-r-15">Puede cancelar hasta {{data.dias_cancelacion}} días de la fecha de llegada</small>
                <a (click)="CancelarReserva()" class="btn btn-danger btn-sm m-r-30"><i class="fa fa-times"></i> Cancelar reserva</a>
              </ng-container>
              <ng-container *ngIf="globals.me.id_rol == 3 && !data.puede_cancelar">
                <small>Esta reserva no permite cancelación actualmente</small>
              </ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cerrar</button>
        </div>
        </div>
    </div>
</div>