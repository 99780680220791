import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-backend-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
  ) { }


  ngOnInit(): void {
    setTimeout(() => {
      App.init();
    }, 500);
  }
  PrimerosPasos() {
    if ($.PrimerosPasos) $.PrimerosPasos();
  }
}
