<div id="page-wrapper" class="page-loading">
  <div class="preloader">
      <div class="inner">
          <!-- Animation spinner for all modern browsers -->
          <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

          <!-- Text for IE9 -->
          <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
      </div>
  </div>
  <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
      <!-- Main Sidebar -->
      <app-backend-menu></app-backend-menu>
      <!-- END Main Sidebar -->

      <!-- Main Container -->
      <div id="main-container">
          <!-- Header -->
          <app-backend-header></app-backend-header>
          <!-- END Header -->

          <!-- Page content -->
          <div id="page-content" class="add-edit-actividad-page">
              <div class="content-header">
                  <div class="row-equal-cols">
                      <div class="col-lg-8 col-md-6 col-xs-12">
                          <div class="header-section">
                              <h1 class="middle">{{title}}</h1>
                              <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                          </div>
                          <ul class="breadcrumb breadcrumb-top text-left">
                              <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                              <li><a [routerLink]="['/alojamientos']">Actividades</a></li>
                              <li>{{title}}</li>
                          </ul>
                      </div>
                      <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                        <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                        <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                      </div>
                  </div>
              </div>

              <div class="block full break-padding">
                  <div class="block-title">
                      <!-- <div class="block-options pull-right">
                          <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                              style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                  class="fa fa-cog"></i></a>
                      </div> -->
                      <ul class="nav nav-tabs" data-toggle="tabs">
                          <li class="active"><a href="#tab-general">Datos generales</a></li>
                          <li><a href="#tab-aforo"><i class="icofont-users"></i> Aforo</a></li>
                          <li><a href="#tab-calendario"><i class="icofont-ui-calendar"></i> Calendario</a></li>
                          <!-- <li><a href="#tab-tarifas"><i class="icofont-credit-card"></i> Tarifas</a></li> -->
                          <!-- <li><a href="#block-tabs-settings" data-toggle="tooltip" title="" data-original-title="Settings"><i class="gi gi-settings"></i></a></li> -->
                      </ul>
                  </div>
                  <div class="tab-content">
                      <div class="tab-pane active" id="tab-general">
                            
                          <div class="row">
                            <div class="col-lg-4 col-md-12">
                              <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                                <i class="icofont-camera-alt font-50"></i>
                              </div>
                              <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                                <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                                <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                                <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                              </div>
                              <hr>
                              <h4 class="hide">Galería de imágenes</h4>
                              <div class="text-right hide" *ngIf="!data.imagenes || data.imagenes.length <= 20" style="margin-top: -30px;margin-bottom: 20px;">
                                <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen a la galería<a href=""></a></a>
                              </div>
                              <div class="text-center hide" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                                <h4>No hay imágenes aún</h4>
                              </div>
                              <div class="row gallery hide" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes" style="padding: 0px 10px;">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id" style="padding-left:5px;padding-right:5px;">
                                    <div class="gallery-image-container animation-fadeInQuick2Inv" style="margin-bottom: 10px;">
                                      <img src="{{item.imagen}}" alt="Image">
                                      <a href="{{item.imagen}}" class="gallery-image-options" data-toggle="lightbox-image" title="Image Info">
                                        <h2 class="text-light" style="margin:0;font-size:12px"><strong>Ampliar</strong></h2>
                                        <i class="fa fa-search-plus fa-3x text-light" style="font-size:12px"></i>
                                      </a>
                                      <a class="btn btn-danger cursor absolute" style="top:0;right:0;" (click)="DelImagen($event, item)"><i class="fa fa-trash"></i></a>
                                    </div>
                                </div>
                              </div>
                              <hr>
                              <h4>Características</h4>
                              <div class="text-right" *ngIf="!data.caracteristicas || data.caracteristicas.length <= 20"  style="margin-top: -30px;margin-bottom: 20px;">
                                <a class="btn btn-primary m-l-20" (click)="AddCaracteristica()">Añadir característica</a>
                              </div>
                              <div class="table-responsive table-grid">
                                  <table class="table table-striped table-bordered table-vcenter table-hover">
                                      <thead>
                                          <tr>
                                            <th style="width:50px"></th>
                                            <th style="width:50px">Icono</th>
                                            <th>Característica</th>
                                            <th style="width:50px">Borrar</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let item of data.caracteristicas" class="cursor" [attr.itemid]="item.id">
                                            <td><i class="fa fa-sort"></i></td>
                                            <td><i class="{{item.icono}}"></i></td>
                                            <td class="font-bold text-primary" (click)="EditCaracteristica(item)">
                                                <div [innerHTML]="item.caracteristica"></div>
                                            </td>
                                            <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelCaracteristica(item)"><i class="fa fa-trash text-white"></i></a></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                              
                              <div class="row">
                                <div class="col-md-12 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary">Nombre *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 col-xs-12">
                                  <div class="row">
                                    <div class="col-lg-8 col-xs-12">
                                      <div class="form-group">
                                        <label class="text-primary">Descripci&oacute;n *</label>
                                        <textarea id="txtdescripcion" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.descripcion" maxlength="5000" rows="4"></textarea>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-12">
                                      <h4>Sesiones</h4>
                                      <div class="text-right" *ngIf="!sesiones || sesiones.length <= 20"  style="margin-top: -30px;margin-bottom: 20px;">
                                        <a class="btn btn-primary m-l-20" (click)="AddSesion()">Añadir sesión</a>
                                      </div>
                                      <div class="table-responsive table-grid">
                                        <table class="table table-striped table-bordered table-vcenter table-hover">
                                            <thead>
                                                <tr>
                                                  <th style="width:50px"></th>
                                                  <th>Sesión</th>
                                                  <th style="width:50px">Borrar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of sesiones" class="cursor">
                                                  <td><i class="icofont-clock-time"></i></td>
                                                  <td class="font-bold text-primary" (click)="EditSesion(item)">
                                                      <div [innerHTML]="item"></div>
                                                  </td>
                                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelSesion(item)"><i class="fa fa-trash text-white"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <!-- <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Adultos *</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.adultos" maxlength="10">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Ni&ntilde;os</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.ninos" maxlength="10">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Número mínimo noches para reservar *</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.num_minimo_noches" maxlength="10">
                                  </div>
                                </div> -->
                                <div class="col-md-8 col-xs-12">
                                  <div class="form-group row">
                                    <label class="col-lg-2 col-form-label">Días semana</label>
                                    <div class="col-lg-10">
                                        <label class="switch switch-success m-r-5">Lunes<br><input type="checkbox" dia="LU" class="chk-dia-temporada" [(ngModel)]="data.lunes"><span></span></label>
                                        <label class="switch switch-success m-r-5">Martes<br><input type="checkbox" dia="MA" class="chk-dia-temporada" [(ngModel)]="data.martes"><span></span></label>
                                        <label class="switch switch-success m-r-5">Miércoles<br><input type="checkbox" dia="MI" class="chk-dia-temporada" [(ngModel)]="data.miercoles"><span></span></label>
                                        <label class="switch switch-success m-r-5">Jueves<br><input type="checkbox" dia="JU" class="chk-dia-temporada" [(ngModel)]="data.jueves"><span></span></label>
                                        <label class="switch switch-success m-r-5">Viernes<br><input type="checkbox" dia="VI" class="chk-dia-temporada" [(ngModel)]="data.viernes"><span></span></label>
                                        <label class="switch switch-success m-r-5">Sábado<br><input type="checkbox" dia="SA" class="chk-dia-temporada" [(ngModel)]="data.sabado"><span></span></label>
                                        <label class="switch switch-success m-r-5">Domingo<br><input type="checkbox" dia="DO" class="chk-dia-temporada" [(ngModel)]="data.domingo"><span></span></label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <label>Aforo predeterminado</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.aforo_predeterminado" maxlength="3">
                                  </div>
                                </div>
                                <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <label>Aforo mínimo</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.aforo_minimo" maxlength="3">
                                  </div>
                                </div>
                                <div class="col-md-5 col-xs-12">
                                  <div class="form-group">
                                    <label>Ubicación</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.ubicacion" maxlength="100" (change)="ActualizarMapa()">
                                  </div>
                                </div>
                                <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <a id="url-gmaps" href="https://www.google.es/maps/place/{{data.ubicacion}},{{data.localidad}}" target="_blank" class="btn btn-primary" style="width: 100%;margin-bottom: 0px;margin-top: 26px;">Abrir en mapa</a>
                                  </div>
                                </div>
                                <!-- <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <label>Día de la semana</label>
                                    <select class="form-control" [(ngModel)]="data.dia_semana">
                                      <option>No seleccionado</option>
                                      <option val="1">Lunes</option>
                                      <option val="2">Martes</option>
                                      <option val="3">Miércoles</option>
                                      <option val="4">Jueves</option>
                                      <option val="5">Viernes</option>
                                      <option val="6">Sábado</option>
                                      <option val="7">Domingo</option>
                                    </select>
                                  </div>
                                </div> -->
                                <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <label>Localidad</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="50" (change)="ActualizarMapa()">
                                  </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Provincia</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="50" (change)="ActualizarMapa()"/>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                
                                
                                <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <label>Precio adulto</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.precio" maxlength="50" />
                                  </div>
                                </div>
                                <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <label>Precio niños</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.precio_ninios" maxlength="50" />
                                  </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Duración</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.duracion" maxlength="50" />
                                  </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                  <div id="content-iframe-gmaps">
                                    <iframe *ngIf="data.ubicacion != null && data.localidad != null && data.provincia != null" width="100%" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="data.link_mapa" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="tab-pane" id="tab-imagenes">
                        <!-- <div class="text-right" *ngIf="!data.imagenes || data.imagenes.length <= 20">
                            <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen</a>
                        </div>
                        <hr>
                        <div class="text-center" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                          <h4>No hay imágenes aún</h4>
                        </div>
                        <div class="row gallery" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes">
                          <div class="col-xl-3 col-md-4 col-sm-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id">
                              <div class="gallery-image-container animation-fadeInQuick2Inv">
                                <img src="{{item.imagen}}" alt="Image">
                                <a class="btn btn-danger cursor absolute" style="top:0;right:0" (click)="DelImagen(item)"><i class="fa fa-trash"></i> Borrar</a>
                              </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="tab-pane" id="tab-caracteristicas">
                          
                        <!-- <div class="text-right" *ngIf="!data.caracteristicas || data.caracteristicas.length <= 20">
                            <a class="btn btn-primary m-l-20" (click)="AddCaracteristica()">Añadir característica</a>
                        </div>
                        <div class="table-responsive table-grid">
                            <table class="table table-striped table-bordered table-vcenter table-hover">
                                <thead>
                                    <tr>
                                      <th style="width:50px"></th>
                                      <th style="width:50px">Icono</th>
                                      <th>Característica</th>
                                      <th style="width:50px">Borrar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.caracteristicas" class="cursor" [attr.itemid]="item.id">
                                      <td><i class="fa fa-sort"></i></td>
                                      <td><i class="{{item.icono}}"></i></td>
                                      <td class="font-bold text-primary" (click)="EditCaracteristica(item)">
                                          <div [innerHTML]="item.caracteristica"></div>
                                      </td>
                                      <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelCaracteristica(item)"><i class="fa fa-trash text-white"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->

                      </div>
                      <div class="tab-pane" id="tab-aforo">
                          
                          <!--<div class="text-right" *ngIf="!data.servicios || data.servicios.length <= 20">
                              <a class="btn btn-primary m-l-20" (click)="AddServicio()">Añadir servicio</a>
                          </div>-->
                          <a class="btn btn-danger" (click)="BorrarAforo()">Restablecer aforo</a>
                          <div class="table-responsive table-grid">
                              <table class="table table-striped table-bordered table-vcenter table-hover" style="width: 1300px;">
                                  <thead>
                                      <tr>
                                        <th style="width:150px">Día</th>
                                        <th style="width:150px">Fecha</th>
                                        <th style="width:150px">Sesión</th>
                                        <th style="width:150px">Aforo total</th>
                                        <th>Aforo disponible</th>
                                        <th style="width:50px">Editar</th>
                                        <th style="width:50px">Bloquear/Desbloquear</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let item of aforo" class="cursor" [attr.itemid]="item.id">
                                        <td>{{item.nombre_dia}} <i *ngIf="item.ocultar == 1" class="fa fa-lock" style="color: red;"></i></td>
                                        <td>{{item.fecha_format}}</td>
                                        <td>{{item.sesion}}</td>
                                        <td>{{item.aforo_total}}</td>
                                        <td><div style="display: flex;"><div style="width: 10%;">{{item.aforo_restante}}</div><div class="progress progress-mini active custom-progress-bar-red" style="height: 12px;
                                          line-height: 12px;
                                          width: 90%;
                                          margin-bottom: 0px;
                                          margin-top: 4px;
                                          margin-left: 20px;">
                                          <div *ngIf="item.ocultar == 0" class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="{{item.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="item.percentage+'%'"></div>
                                          <div *ngIf="item.ocultar == 1" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="{{item.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="item.percentage+'%'"></div>
                                          </div></div>
                                        </td>
                                        <td class="text-center btntd"><a class="btn btn-warning btn-xs" (click)="EditarDia(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                        <td class="text-center btntd" *ngIf="item.ocultar == 0"><a class="btn btn-success btn-xs" (click)="BloquearDiaAforo(item)"><i class="fa fa-unlock text-white"></i></a></td>
                                        <td class="text-center btntd" *ngIf="item.ocultar == 1"><a class="btn btn-danger btn-xs" (click)="DesbloquearDiaAforo(item)"><i class="fa fa-lock text-white"></i></a></td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>

                      </div>
                      <!-- <div class="tab-pane" id="tab-tarifas">
                          
                        <div class="text-right m-b-10">
                          <div class="btn-group">
                            <a class="btn btn-sm btn-danger" (click)="DelAllTarifas()">Borrar todas las tarifas</a>
                            <a class="btn btn-sm btn-default" (click)="OrdenarTarifas()" *ngIf="data.id && data.id != ''">Ordenar automáticamente</a>
                            <a class="btn btn-sm btn-default" (click)="GenerarTarifas()">Generar tarifas por temporadas</a>
                            <a class="btn btn-sm btn-default" (click)="ImportarTarifas()">Importar tarifas</a>
                            <a class="btn btn-sm btn-primary" (click)="AddTarifa()">Añadir tarifa</a>
                          </div>
                        </div>
                        <div class="pull-left m-r-15 text-primary">
                          <div class="text-center">
                            <i class="fa fa-arrow-up"></i><br>
                            <span class="font-12">Prioridad<br>alta</span>
                          </div>
                          <div class="text-center m-t-20">
                            <i class="fa fa-arrow-down"></i><br>
                            <span class="font-12">Prioridad<br>baja</span>
                          </div>
                        </div>
                        <div class="table-responsive table-grid">
                            <table class="table table-striped table-bordered table-vcenter table-hover">
                                <thead>
                                    <tr>
                                      <th style="width:50px"></th>
                                      <th style="width:100px">Tarifa</th>
                                      <th>Temporada</th>
                                      <th>Adultos</th>
                                      <th>Niños</th>
                                      <th>Precio</th>
                                      <th style="width:50px">Editar</th>
                                      <th style="width:50px">Borrar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.tarifas; let i = index" class="cursor" [attr.itemid]="item.id">
                                      <td><i class="fa fa-sort"></i></td>
                                      <td class="font-bold" (click)="EditTarifa(item)">Tarifa #{{i+1}}</td>
                                      <td class="font-bold" (click)="EditTarifa(item)">
                                        <div *ngIf="item.temporada">{{item.temporada}}
                                          <small *ngIf="item.pordefecto" class="text-primary m-l-10">Por defecto</small>
                                        </div>
                                      </td>
                                      <td>{{item.adultos}}</td>
                                      <td>{{item.ninos}}</td>
                                      <td>{{globals.FloatES(item.precio)}}€ / noche</td>
                                      <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="EditTarifa(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                      <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelTarifa(item)"><i class="fa fa-trash text-white"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="text-primary">El orden de las tarifas es muy importante en el cálculo del total de las reservas</p>

                      </div> -->
                      <div class="tab-pane" id="tab-calendario">
                        <div class="row">
                          <div class="col-md-7 col-xs-12">
                            <div class="pull-right" style="width: 40%;">
                              <h2>Legenda</h2>
                              <div class="legenda">
                                <div><span class="bloqueada airbnb"></span> AirBnb</div>
                              </div>
                            </div>
                            <h2>Fechas reservadas</h2>
                            <div id="calendario-cierres" class="calendario-open"></div>
                          </div>
                          <div class="col-md-5 col-xs-12">
                            <h2>Sincronización calendarios</h2>
                            <div class="form-group">
                              <label class="text-primary">URL Calendario Airbnb (.ics)</label>
                              <input type="url" class="form-control" [(ngModel)]="data.url_calendario_airbnb" maxlength="400">
                            </div>
                            <ng-container *ngIf="data && data.id && data.id != ''">
                              <hr>
                              <h4>URL calendario reservas exportado (.ics)</h4>
                              <a [href]="globals.api_url+'/export-ics/'+data.id" target="_blank" class="text-primary">{{globals.api_url+'/export-ics/'+data.id}}</a>
                            </ng-container>
                          </div>
                        </div>

                      </div>
              </div>
  
              <div class="col-botones">
                <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
              </div>

          </div>
          <!-- END Page Content -->
      </div>
      <!-- END Main Container -->
  </div>
  <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-crear-caracteristica" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5 col-xs-12">
            <div class="form-group">
              <label>Predefinidas</label><br>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-ruler-alt-1', '35 m<sup>2</sup>')">
                <i class="icofont-ruler-alt-1"></i>
                35 m<sup>2</sup>
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-user-alt-1', '2 personas')">
                <i class="icofont-user-alt-1"></i>
                2 personas
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-sunny-day-temp', 'Climatizado')">
                <i class="icofont-sunny-day-temp"></i>
                Climatizado
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-sunny-day-temp', 'Calefacción')">
                <i class="icofont-sunny-day-temp"></i>
                Calefacción
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-no-smoking', 'No fumador')">
                <i class="icofont-no-smoking"></i>
                No fumador
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-wifi', 'Wi-Fi')">
                <i class="icofont-wifi"></i>
                Wi-Fi
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-imac', 'Televisor')">
                <i class="icofont-imac"></i>
                Televisor
              </div>
            </div>
          </div>
          <div class="col-md-7 col-xs-12">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Característica <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.caracteristica" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
                <div class="col-lg-8 chosen-iconos cmb-icono-caracteristica">
                  <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
                  </select>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-caracteristica" (click)="GuardarCaracteristica()">Guardar</button>
      </div>
      </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-crear-sesion" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Sesión <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="time" class="form-control" [(ngModel)]="sesion" maxlength="5">
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-sesion" (click)="GuardarSesion()">Guardar</button>
      </div>
      </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-crear-servicio" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Servicio <span class="text-danger">*</span></label>
            <div class="col-lg-8">
                <input type="text" class="form-control" [(ngModel)]="dataitem.servicio" maxlength="50">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
            <div class="col-lg-8 chosen-iconos cmb-icono-servicio">
              <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
              </select>
            </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-servicio" (click)="GuardarServicio()">Guardar</button>
      </div>
      </div>
  </div>
</div>
<!-- <div class="modal inmodal fade" id="modal-crear-tarifa" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Por defecto (tarifa base)</label>
            <div class="col-lg-8">
                <label class="switch switch-success"><input id="chk-pordefecto" type="checkbox" [(ngModel)]="dataitem.pordefecto"><span></span></label>
            </div>
        </div>
        <div class="form-group row {{dataitem.pordefecto ? 'hidden' : ''}}">
            <label class="col-lg-4 col-form-label">Temporada <span class="text-danger">*</span></label>
            <div class="col-lg-8">
              <select id="cmb-temporada" class="form-control" [(ngModel)]="dataitem.id_temporada">
                <option *ngFor="let item of temporadas" [value]="item.id">{{item.temporada}}</option>
              </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Adultos <span class="text-danger">*</span></label>
            <div class="col-lg-8">
                <input type="number" class="form-control" placeholder="Nº Adultos" [(ngModel)]="dataitem.adultos" maxlength="20">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Niños</label>
            <div class="col-lg-8">
                <input type="number" class="form-control" placeholder="Nº Niños" [(ngModel)]="dataitem.ninos" maxlength="20">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Precio <span class="text-danger">*</span></label>
            <div class="col-lg-8">
                <input type="number" class="form-control" placeholder="Precio por noche" [(ngModel)]="dataitem.precio" maxlength="20"><br>
            </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-tarifa" (click)="GuardarTarifa()">Guardar</button>
      </div>
      </div>
  </div>
</div> -->
<div class="modal inmodal fade" id="modal-editar-dia" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title">Editar aforo del día {{diaAforo.fecha_format}}</h4>
      </div>
      <div class="modal-body">
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Aforo total</label>
            <div class="col-lg-8">
              <input class="form-control" id="aforo-total" type="number" [(ngModel)]="diaAforo.aforo_total"/>
            </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Aforo disponible</label>
          <div class="col-lg-8">
            <input class="form-control" id="aforo-restante" type="number" disabled [(ngModel)]="diaAforo.aforo_restante"/>
          </div>
      </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal" (click)="LoadAforo()">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-dia-aforo" (click)="GuardarDiaAforo()">Guardar</button>
      </div>
      </div>
  </div>
</div>