<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>
  
            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->
  
        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->
  
            <!-- Page content -->
            <div id="page-content" class="add-edit-actividad-page">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/cupones']">Cupones</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label>Nombre <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                        </div>
                        <div class="form-group">
                          <label>Descripción</label>
                          <textarea class="form-control" [(ngModel)]="data.descripcion" maxlength="5000" rows="5"></textarea>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                  <label>Código <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" [(ngModel)]="data.codigo" maxlength="50">
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                  <label>Tipo de descuento</label>
                                  <select class="form-control" [(ngModel)]="data.tipo">
                                      <option value="porcentaje" selected>Porcentaje</option>
                                      <option value="importe">Importe</option>
                                  </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                  <label>Estado</label>
                                  <select class="form-control" [(ngModel)]="data.activo" style="width:auto;">
                                      <option value="1" selected>Activado</option>
                                      <option value="0">Desactivado</option>
                                  </select>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label *ngIf="data.tipo == 'porcentaje'">Porcentaje de descuento</label>
                                    <label *ngIf="data.tipo == 'importe'">Importe de descuento</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control" [(ngModel)]="data.importe">
                                        <div class="input-group-append cursor">
                                            <span class="input-group-addon" *ngIf="data.tipo == 'porcentaje'">%</span>
                                            <span class="input-group-addon" *ngIf="data.tipo == 'importe'">€</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <h4>Configuración</h4>
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Fecha inicio</label>
                          <input type="date" class="form-control fec-inicio" [(ngModel)]="data.fecha_inicio" maxlength="20">
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Fecha fin</label>
                          <input type="date" class="form-control fec-fin" [(ngModel)]="data.fecha_fin" maxlength="20">
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Gasto mínimo</label>
                          <input type="number" class="form-control" [(ngModel)]="data.gasto_minimo" maxlength="20">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Límite por cliente</label>
                          <input type="number" class="form-control" [(ngModel)]="data.limite_cliente" maxlength="20">
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Límite total</label>
                          <input type="number" class="form-control" [(ngModel)]="data.limite_total" maxlength="20">
                        </div>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>
  
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
  </div>