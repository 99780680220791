import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Html5Qrcode } from 'src/assets/js/html5-qrcode-master/src';
// // To use Html5QrcodeScanner (more info below)
// import {Html5QrcodeScanner} from 'src\assets\js\html5-qrcode-master\minified\html5-qrcode.min.js';

// // To use Html5Qrcode (more info below)
// import {Html5Qrcode} from "html5-qrcode";

declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-backend-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  public aforo = [];
  public mesesAforo:any = [];
  public historicoReservas:any = [];
  public actividades:any = [];
  public idCalendario:any;
  public primeraCargaCalendario = true;
  public sesiones:any = [];
  public aforoDia:any = [];
  public idUsuario:any;
  public dataReserva:any = {
    id_evento:    '',
    fecha:        '',
    sesion:       '',
    adultos:      0,
    ninios:       0,
    niniosGratis: 0,
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    precio:       0,
    tipoPago:     ''
  };
  public precioAdulto:any;
  public precioNinio:any;
  public precioCalculoAdulto:any = 0;
  public precioCalculoNinio:any = 0;
  public plazasDisponibles:any = 50;
  public rankingColaboradores:any;

  public html5QrCode:any;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
    ) {
    $.PrimerosPasos = () => {
      $('.onboard-modal.show-on-load').modal('show');
      if ($('.onboard-modal .onboard-slider-container').length) {
        $('.onboard-modal .onboard-slider-container').slick({
          dots: true,
          infinite: false,
          adaptiveHeight: true,
          touchMove: true,
          swipe: true,
          slidesToShow: 1,
          prevArrow: '<button type="button" data-role="none" class="slick-prev">Anterior</button>',
          nextArrow: '<button type="button" data-role="none" class="slick-next">Siguiente</button>',
        });
        $('.onboard-modal').on('shown.bs.modal', function (e) {
          $('.onboard-modal .onboard-slider-container').slick('setPosition');
        });
      }
      this.globals.SaveData(true, 'onboarding');
    };
    $.NuevaReservaGeneral = (fecha:any = null) => {
      $('#date-reservar-modal-reservas-hoy').val(moment().format('YYYY-MM-DD'));
      if (fecha) $('#date-reservar-modal-reservas-hoy').val(moment(fecha).format('YYYY-MM-DD'));
      $('#modal-reservas-hoy').modal('show');
    };
    
    switch(this.globals.me.id_rol) {
      case 4:{
        $(document).ready(() => {
          if (!this.globals.GetData('onboarding')) {
            $.PrimerosPasos();
          }
        });
      } break;
    };
  }

  ngOnInit(): void {
    $.checkpermission();
  }
  Logout() {
    if ($.Logout) $.Logout();
  }
  callCameraQr(){
    Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        var cameraId = devices[0].id;
        // .. use this to start scanning.
        this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
        $('#close-lector-qr').show();
        window.scrollTo(0, 0);
        $('body').addClass('stop-scrolling');
        
        this.html5QrCode.start(
          { facingMode: "environment"
          }, 
          {
            fps: 60,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
          },
          (decodedText, decodedResult) => {
            // do something when code is read
              // let dataQr = decodedText.split(':');
              // if(dataQr.length != 2){
              //   swal('Error', 'QR no válido', 'error');
              // }else{
                
              // }
              this.globals.Api('/check-reserva-qr', {token_reserva: decodedText}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Reserva ya validada', 'error');return;
                }
                swal('Correcto','Reserva válida para '+data.value.cli_nombre+' '+data.value.cli_apellidos,'success');
              }, error => {
                swal('Error', 'Se ha producido un error conectando con el servidor', 'error');
              });
              this.html5QrCode.stop().then((ignore) => {
                // QR Code scanning is stopped.
              }).catch((err) => {
                // Stop failed, handle it.
              });
              $('body').removeClass('stop-scrolling');
              $('#close-lector-qr').hide();
          },
          (errorMessage) => {
            // parse error, ignore it.
          })
        .catch((err) => {
          // Start failed, handle it.
        });
      }else{
        swal('Error', 'Este dispositivo no tiene cámara', 'error');
      }
    }).catch(err => {
      // handle err
        swal('Error', 'Este dispositivo no tiene cámara', 'error');
    });
    
  }
  cerrarLector(){
    this.html5QrCode.stop().then((ignore) => {
      // QR Code scanning is stopped.
    }).catch((err) => {
      // Stop failed, handle it.
      swal('Error', 'Error al detener el lector', 'error');
    });
    $('#close-lector-qr').hide();
    $('body').removeClass('stop-scrolling');
  }

  
  ComprobarGuardarReserva(){
    if(
      this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00' ||
      this.dataReserva.sesion == null || this.dataReserva.sesion == '' ||
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''
    ){
      swal('Error', 'Rellena todos los datos', 'error');return;
      //setInterval
    }
    let aforoCheck = (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis);
    let that = this;
    this.globals.Api('/comprobar-aforo',{idEvento: this.idCalendario, aforoCheck: aforoCheck, dia: this.dataReserva.fecha, sesion: this.dataReserva.sesion}).subscribe(data => {
      // console.log(data);
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      if(data.results == 0){
        swal('Error', 'Error al validar el aforo, revísalo en el calendario', 'warning');return;
      }
      that.GuardarReserva();
    });
  }
  GuardarReserva(){
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    this.globals.Api('/eve-reservas-calendar-add',this.dataReserva).subscribe(data => {
      // console.log(data);
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');return;
      }
      swal('Guardado', '¡Reserva hecha con éxito!', 'success');
      $('#modal-reservas-hoy').modal('hide');
    });
  }
  AnteriorModal(){
    $('#paso-1-modal-reservas-hoy').fadeIn();
    $('#paso-2-modal-reservas-hoy').hide();
  }
  SiguienteModal(){
    console.log('Click siguiente');
    var radiosSeleccion = $('.radios-sesion');
    var checkRadio = false;
    for(var i = 0; i < radiosSeleccion.length; i++){
      if($(radiosSeleccion[i]).is(':checked')){
        checkRadio = true;
      }
    }
    if(!checkRadio){
      swal('¡Atención!', 'Debe seleccionar una sesión', 'warning');
      return;
    }
    if(this.dataReserva.adultos == 0){
      swal('¡Atención!', 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }
    let aforoCheck = (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis);
    this.globals.Api('/comprobar-aforo',{idEvento: this.idCalendario, aforoCheck: aforoCheck, dia: this.dataReserva.fecha, sesion: this.dataReserva.sesion}).subscribe(data => {
      // console.log(data);
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      if(data.results == 0){
        swal('Error', 'Error al validar el aforo, revísalo en el calendario', 'warning');return;
      }
      $('#paso-1-modal-reservas-hoy').hide();
      $('#paso-2-modal-reservas-hoy').fadeIn();
    });
  }
  calcularPrecioModal(){
    this.precioCalculoAdulto = this.dataReserva.adultos * this.precioAdulto;
    this.precioCalculoNinio = this.dataReserva.ninios * this.precioNinio;
    this.dataReserva.precio = this.precioCalculoAdulto + this.precioCalculoNinio;
  }
  SelectReservaProveedor(event){
    //console.log(event);
    if($('#select-colaboradores-reserva-admin').val() != 'admin'){
      this.dataReserva.id_colaborador = $('#select-colaboradores-reserva-admin').val();
      this.dataReserva.tipo = 'Partner';
    }else{
      this.dataReserva.id_colaborador = '';
      this.dataReserva.tipo = 'admin';
    }
  }

}
