<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                  <div class="seccion-header">
                    <span>
                      <div class="header-section">
                        <h1 class="middle" *ngIf="!misreservas">Reservas</h1>
                        <h1 class="middle" *ngIf="misreservas">Mis reservas</h1>
                        <button class="btn btn-xs btn-secondary middle m-l-10" (click)="Actualizar()"><i class="fa fa-refresh"></i></button>
                      </div>
                      <ul class="breadcrumb breadcrumb-top text-left">
                          <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                          <li *ngIf="!misreservas">Reservas</li>
                          <li *ngIf="misreservas">Mis reservas</li>
                      </ul>
                    </span>
                    <span>
                      <label class="col-form-label inline-block m-r-10">Mostrar</label>
                      <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                      </select>
                    </span>
                    <span style="min-width: 50%;">
                      <div class="input-group width-100">
                        <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                        <div class="input-group-append cursor" (click)="Buscar()">
                          <span class="input-group-addon"><i class="fa fa-search"></i></span>
                        </div>
                      </div>
                    </span>
                    <span *ngIf="GetTabActive() == '#tab-listado'">
                      <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.cupon" style="width:auto;">
                        <option value="">Cupón</option>
                        <option [value]="item.codigo" *ngFor="let item of cupones">{{item.codigo}}</option>
                      </select>
                    </span>
                  </div>
                </div>
                <!-- Table Styles Content -->
                <ul class="nav nav-tabs nav-principal break-padding m-t--20" data-toggle="tabs">
                  <li class="active"><a href="#tab-calendario"><i class="fa fa-calendar"></i> Vista calendario</a></li>
                  <li><a href="#tab-listado"><i class="fa fa-list"></i> Vista listado</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="tab-calendario">
                    <div class="full break-padding">
                      <div class="row">
                        <div class="col-lg-4 col-lg-push-8 col-md-12 col-xs-12">
                            <div class="block full calendar-container">
                                <div class="block-title">
                                    <h2>Calendario</h2>
                                </div>
                                <div id="calendar" class="calendario-listado-reservas"></div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-lg-pull-4 col-md-12 col-xs-12">
                            <div class="block full">
                                <div class="block-title">
                                  <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                      <h2> 
                                          <span class="text-primary m-l-5 font-20" *ngIf="params_calendario.fecha_format">{{params_calendario.fecha_format}}</span>
                                          <span class="m-l-15 font-20" *ngIf="reservas_calendario && reservas_calendario.length" style="text-transform: none;"><span>Total:</span> {{reservas_calendario.length}}</span>
                                          <span class="m-l-30 font-20" *ngIf="reservas_calendario && reservas_calendario.length" style="text-transform: none;"><span>Nº Personas: </span>{{GetTotalPersonas()}}</span>
                                      </h2>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                      <div class="btn-sesiones m-t-5 m-b-5 text-center">
                                          <button type="button" class="btn btn-primary btn-todas active m-r-10" (click)="mostrarTodasSesiones()">
                                            Todas<br>
                                            <span>Personas: <strong>{{GetTotalPersonas()}}</strong></span>
                                          </button>
                                          <ng-container *ngFor="let sesion of sesiones;let i = index">
                                            <button type="button" class="btn btn-primary sesion-{{i}} m-r-10" *ngIf="sesion" (click)="mostrarSesion(sesion, i)">
                                              <i class="icofont-clock-time"></i> Sesión {{sesion}}<br>
                                              <span>Personas: <strong>{{GetTotalPersonasSesion(sesion)}}</strong></span>
                                            </button>
                                          </ng-container>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                                <div class="no-resultados text-center" *ngIf="!reservas_calendario || !reservas_calendario.length">
                                    <div>No existen reservas para esta fecha.</div>
                                    <a class="btn btn-primary m-t-10 m-b-30" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                                </div>
                                <div class="row row-flexbox">
                                    <ng-container *ngFor="let item of reservas_calendario">
                                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;">
                                            <a href="javascript:void(0)" class="
                                              widget 
                                              reserva-restaurante 
                                              {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                              " (click)="Editar(item)">
                                                <div class="widget-content text-right clearfix">
                                                    <div class="pull-left icon-tipo text-left" style="width:38%;">
                                                        <!-- <i class="fa fa-ticket sidebar-nav-icon img-circle img-thumbnail img-thumbnail-avatar"
                                                            style="font-size:35px;text-align:center;color:var(--color-primary);line-height: 1.6;"></i> -->
                                                        <span class="badge-reserva">
                                                          <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" />
                                                          <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" />
                                                          <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" />
                                                          <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" />
                                                          <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" />
                                                          <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" />
                                                          {{item.tipo}}
                                                        </span>
                                                        <h5 class="text-left h5">Fecha: <strong>{{item.fecha_format}}</strong></h5>
                                                        <h5 class="text-left h5">Sesion: <strong>{{item.sesion}}</strong></h5>
                                                        <h5 class="text-left h5" *ngIf="item.cli_tipo_pago">Pago: <strong>{{item.cli_tipo_pago}}</strong></h5>
                                                        <h5 class="text-left h5" *ngIf="item.id_pago">ID Pago:<br><small class="breakword" style="font-weight:400;">{{item.id_pago}}</small></h5>
                                                        <h5 class="text-left h5">Estado: <strong>{{item.estado}}</strong></h5>
                                                    </div>
                                                    <span *ngIf="item.id_colaborador" class="autor">Creada por: {{item.nombre_colaborador}}</span>
                                                    <h3 class="widget-heading h4">
                                                      <img src="assets/flag-en.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'en'">
                                                      <img src="assets/flag-it.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'it'">
                                                      <img src="assets/flag-fr.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'fr'">
                                                      <img src="assets/flag-pt.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'pt'">
                                                      <strong class="nombre">{{item.cli_nombre}} {{item.cli_apellidos}}</strong>
                                                    </h3>
                                                    <div class="h4"><strong>{{CalcularPersonas(item.adultos,item.ninos,item.ninos_gratis)}}</strong> personas</div>
                                                    <div class="text-muted breakword" *ngIf="item.cli_email"><i class="fa fa-envelope-o"></i> {{item.cli_email}}</div>
                                                    <div class="text-muted" *ngIf="item.cli_telefono"><i class="fa fa-phone"></i> {{item.cli_telefono}}</div>
                                                    <div class="m-t-10">
                                                        <a class="btn btn-sm btn-primary" (click)="EnviarEmail(item, $event)" *ngIf="item.cli_email && item.id_estado == 3"><i class="fa fa-envelope font-14"></i></a>
                                                        <a class="btn btn-sm btn-success m-l-10" (click)="EnviarWhatsapp(item, $event)" *ngIf="item.cli_telefono"><i class="fa fa-whatsapp font-14"></i></a>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="GetReservasCanceladas().length">
                                  <h3 class="text-center font-bold">Reservas canceladas o fallidas</h3>
                                  <hr style="border-color:#000;">
                                  <div class="row row-flexbox">
                                      <ng-container *ngFor="let item of GetReservasCanceladas()">
                                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;">
                                              <a href="javascript:void(0)" class="
                                                widget 
                                                reserva-restaurante 
                                                {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                                " (click)="Editar(item)">
                                                  <div class="widget-content text-right clearfix">
                                                    <div class="pull-left icon-tipo text-left" style="width:38%;">
                                                          <!-- <i class="fa fa-ticket sidebar-nav-icon img-circle img-thumbnail img-thumbnail-avatar"
                                                              style="font-size:35px;text-align:center;color:var(--color-primary);line-height: 1.6;"></i> -->
                                                          <span class="badge-reserva">
                                                            <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" />
                                                            <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" />
                                                            <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" />
                                                            <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" />
                                                            <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" />
                                                            <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" />
                                                            {{item.tipo}}
                                                          </span>
                                                          <h5 class="text-left h5">Fecha: <strong>{{item.fecha_format}}</strong></h5>
                                                          <h5 class="text-left h5">Sesion: <strong>{{item.sesion}}</strong></h5>
                                                          <h5 class="text-left h5" *ngIf="item.cli_tipo_pago">Pago: <strong>{{item.cli_tipo_pago}}</strong></h5>
                                                          <h5 class="text-left h5" *ngIf="item.id_pago">ID Pago:<br><small class="breakword" style="font-weight:400;">{{item.id_pago}}</small></h5>
                                                          <h5 class="text-left h5">Estado: <strong>{{item.estado}}</strong></h5>
                                                      </div>
                                                      <span *ngIf="item.id_colaborador" class="autor">Creada por: {{item.nombre_colaborador}}</span>
                                                      <h3 class="widget-heading h4">
                                                        <img src="assets/flag-en.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'en'">
                                                        <img src="assets/flag-it.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'it'">
                                                        <img src="assets/flag-fr.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'fr'">
                                                        <img src="assets/flag-pt.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'pt'">
                                                        <strong class="nombre">{{item.cli_nombre}} {{item.cli_apellidos}}</strong>
                                                      </h3>
                                                      <div class="h4"><strong>{{CalcularPersonas(item.adultos,item.ninos,item.ninos_gratis)}}</strong> personas</div>
                                                      <div class="text-muted breakword" *ngIf="item.cli_email"><i class="fa fa-envelope-o"></i> {{item.cli_email}}</div>
                                                      <div class="text-muted" *ngIf="item.cli_telefono"><i class="fa fa-phone"></i> {{item.cli_telefono}}</div>
                                                      <div class="m-t-10">
                                                          <a class="btn btn-sm btn-primary" (click)="EnviarEmail(item, $event)" *ngIf="item.cli_email && item.id_estado == 3"><i class="fa fa-envelope font-14"></i></a>
                                                          <a class="btn btn-sm btn-success m-l-10" (click)="EnviarWhatsapp(item, $event)" *ngIf="item.cli_telefono"><i class="fa fa-whatsapp font-14"></i></a>
                                                      </div>
                                                  </div>
                                              </a>
                                          </div>
                                      </ng-container>
                                  </div>
                                </ng-container>
                                <!-- <div class="text-center p-t-10" style="border-top: 1px solid #EEE" *ngIf="reservas && reservas.length">
                                    <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                                </div> -->
                            </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="tab-pane" id="tab-listado">
                    <div class="table-responsive table-grid block full break-padding">
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                            <thead>
                                <tr>
                                  <th order="referencia">Referencia</th>
                                  <th>Via reserva</th>
                                  <th style="width:100px" order="alta">Alta</th>
                                  <th style="width:150px" order="estado">Estado</th>
                                  <th>Fecha/Sesión</th>
                                  <th>Personas</th>
                                  <th>Cliente</th>
                                  <th>Total</th>
                                  <th>Validada</th>
                                  <ng-container *ngIf="!misreservas">
                                    <th style="width:50px">Editar</th>
                                    <th style="width:50px">Borrar</th>
                                  </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngIf="!misreservas">
                                <tr *ngFor="let item of reservas" class="cursor {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}">
                                  <td class="" (click)="Editar(item)">
                                    <div class="font-bold text-primary">Reserva #{{item.referencia}}</div>
                                    <div class="text-secondary">{{item.evento}}</div>
                                  </td>
                                  <td *ngIf="item.tipo != 'Partner'">
                                    <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" style="height:20px;" />
                                    {{item.tipo}}
                                  </td>
                                  <td *ngIf="item.tipo == 'Partner'">
                                    <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" style="height:20px;" />
                                    {{item.tipo}} ({{item.nombre_colaborador}})
                                  </td>
                                  <td>{{item.alta_format}}</td>
                                  <td>
                                    <div class="font-bold">{{item.estado}}</div>
                                    <div *ngIf="item.cli_tipo_pago && item.cli_tipo_pago != '0'" class="font-11 text-primary">{{item.cli_tipo_pago}}</div>
                                  </td>
                                  <td>
                                      <div>{{item.fecha_format}}</div>
                                      <div>{{item.sesion}}</div>
                                  </td>
                                  <td>
                                      <div *ngIf="item.adultos">Adultos: {{item.adultos}}</div>
                                      <div *ngIf="item.ninos">Ni&ntilde;os: {{item.ninos}}</div>
                                  </td>
                                  <td>
                                      <div class="nombre-listado">
                                        <img src="assets/flag-en.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'en'">
                                        <img src="assets/flag-it.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'it'">
                                        <img src="assets/flag-fr.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'fr'">
                                        <img src="assets/flag-pt.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'pt'">
                                        {{item.cli_nombre_completo}}
                                      </div>
                                      <div *ngIf="item.cli_email"><a href="mailto:{{item.cli_email}}"><i class="fa fa-envelope"></i> {{item.cli_email}}</a></div>
                                      <div *ngIf="item.cli_telefono"><a href="tel:{{item.cli_telefono}}"><i class="fa fa-phone"></i> {{item.cli_telefono}}</a></div>
                                  </td>
                                  <td class="font-bold text-primary text-center m-l-20" (click)="Editar(item)">
                                    <div class="font-26">{{globals.FloatES(item.total)}} &euro;</div>
                                  </td>
                                  <td class="text-center btntd">
                                    <div class="cel-validada" *ngIf="item.validada == 0"><i class="fa fa-times" style="color: red; font-size: 22px;"></i></div>
                                    <div class="cel-validada" *ngIf="item.validada == 1"><i class="fa fa-check" style="color: green; font-size: 22px;"></i></div>
                                  </td>
                                  <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="misreservas">
                                <tr *ngFor="let item of reservas" class="cursor {{item.id_estado == 4 || item.id_estado == 5 ? 'bg-tr-cancelada' : null}}" (click)="VistaPrevia(item)">
                                  <td class="">
                                    <div class="font-bold text-primary">Reserva #{{item.referencia}}</div>
                                    <div class="font-bold">{{item.alojamiento}}</div>
                                  </td>
                                  <td>{{item.alta_format}}</td>
                                  <td>
                                    <div>{{item.estado}}</div>
                                    <div *ngIf="item.forma_pago && item.forma_pago != '0'" class="font-11 text-primary">{{item.forma_pago}}</div>
                                  </td>
                                  <td>
                                      <div>Llegada: {{item.fecha_llegada_format}}</div>
                                      <div>Salida: {{item.fecha_salida_format}}</div>
                                      <div>Noches: {{item.noches}}</div>
                                  </td>
                                  <td>
                                      <div *ngIf="item.adultos">Adultos: {{item.adultos}}</div>
                                      <div *ngIf="item.ninos">Ni&ntilde;os: {{item.ninos}}</div>
                                  </td>
                                  <td class="breaktd">
                                      <div>{{item.cli_nombre_completo}}</div>
                                      <div><a href="mailto:{{item.cli_email}}"><i class="fa fa-envelope"></i> {{item.cli_email}}</a></div>
                                      <div><a href="tel:{{item.cli_telefono}}"><i class="fa fa-phone"></i> {{item.cli_telefono}}</a></div>
                                  </td>
                                  <td class="font-bold text-primary text-center breaktd">
                                    <div class="font-20">{{globals.FloatES(item.total)}} &euro;</div>
                                  </td>
                                  <td class="text-center">
                                    <div class="cel-validada" *ngIf="item.validada == 0"><i class="fa fa-times"></i></div>
                                    <div class="cel-validada" *ngIf="item.validada == 1"><i class="fa fa-check"></i></div>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                        </table>
                        <div class="dataTables_paginate paging_simple_numbers m-r-10">
                          <ul class="pagination justify-content-end">
                            <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                              <a (click)="Paging('prev')" class="page-link">Anterior</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                              <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                            </li>
                            <li class="paginate_button page-item active">
                              <a class="page-link cursor">{{params.page_index}}</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                              <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                            </li>
                            <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                              <a (click)="Paging('next')" class="page-link">Siguiente</a>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>