import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;

@Component({
  selector: 'app-confirmacion-resenia',
  templateUrl: './confirmacion-resenia.component.html',
  styles: [
  ]
})
export class ConfirmacionReseniaComponent implements OnInit {

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
    ) { }

  ngOnInit(): void {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailB64 = urlParams.get('em');
    const nombreB64 = urlParams.get('no');
    const id = urlParams.get('id');
    const email = this.b64DecodeUnicode(emailB64);
    const nombre = this.b64DecodeUnicode(nombreB64);
    this.globals.Api('/pub-registrar-resenia', {id_reserva: id, cli_nombre: nombre, cli_email: email}).subscribe(data => {
      console.log(data);
      if (!data || data.length == 0) return;
      this.reedireccionar();
    });
  }
  reedireccionar(){
    window.location.href = "https://www.google.com/search?q=tablaoflamencoandalusi&oq=ta&aqs=edge.1.69i60j69i59j69i57j0i131i433i512j0i512j69i60l4.2287j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd126dc2e794b0cb:0x24351857b1d9da2d,1,,,";
  }
  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
}
