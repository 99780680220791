<div class="response-reserva">
    <h1>{{L('¡Gracias por tu reserva')}}!</h1>
    <p>{{L('A continuación te mostramos los detalles de tu reserva')}}:</p>
    <div class="row row-small datos-reserva" *ngIf="data && data.id">
      <div class="col-xs-6">
        <ul class="list-group">
            <li class="list-group-item active">{{L('Tus datos')}}</li>
            <li class="list-group-item datos">
              <div>{{data.cli_nombre}} {{data.cli_apellidos}}</div>
              <div *ngIf="data.cli_empresa">{{data.cli_empresa}}<span *ngIf="data.cli_nifcif">, {{data.cli_nifcif}}</span></div>
              <div>{{data.cli_direccion}}<span *ngIf="data.cli_codpostal">, {{data.cli_codpostal}}</span></div>
              <div>{{data.cli_localidad}}<span *ngIf="data.cli_pais">, {{data.cli_pais}}</span></div>
              <div *ngIf="data.cli_email"><i class="fa fa-envelope-o"></i> {{data.cli_email}}</div>
              <div *ngIf="data.cli_telefono"><i class="fa fa-phone"></i> {{data.cli_telefono}}</div>
              <div *ngIf="data.cli_notas"><br>{{data.cli_notas}}</div>
            </li>
        </ul>
        <ul class="list-group">
            <li class="list-group-item active">{{L('Modalidad de pago')}}</li>
            <li class="list-group-item datos">
              <div class="dato-destacado">{{data.cli_tipo_pago}}</div>
            </li>
        </ul>
      </div>
      <div class="col-xs-6">
        <ul class="list-group">
            <li class="list-group-item active">{{L('Datos de la reserva')}}</li>
            <li class="list-group-item datos">
              <div class="dato-destacado">#{{data.referencia}}</div>
              <div class="dato-destacado">{{data.evento}}</div>
              <div>{{L('Fecha del evento')}}: {{data.fecha_format}}</div>
              <div>{{L('Sesión del evento')}}: {{data.sesion}}</div>
              <div>{{L('Adultos')}}: {{data.adultos}}</div>
              <div>{{L('Niños')}}: {{data.ninos}}</div>
              <div>{{L('Niños gratis')}}: {{data.ninos_gratis}}</div>
            </li>
            <ng-container *ngIf="data.descuento">
              <li class="list-group-item">{{L('Total sin desc.')}}:
                <div class="pull-right">{{data.total_sin_descuento_format}}€</div>
              </li>
              <li class="list-group-item">{{L('Descuento')}}:
                <div class="pull-right">{{data.descuento}}</div>
              </li>
            </ng-container>
            <li class="list-group-item active font-bold font-20">
              {{L('Total')}}
              <div class="pull-right">{{data.total_format}} €</div>
            </li>
        </ul>
        <div class="clearfix"></div>
      </div>
    </div>
</div>