<!-- In the PHP version you can set the following options from inc/config file -->
<!--
    Available header.navbar classes:

    'navbar-default'            for the default light header
    'navbar-inverse'            for an alternative dark header

    'navbar-fixed-top'          for a top fixed header (fixed main sidebar with scroll will be auto initialized, functionality can be found in js/app.js - handleSidebar())
        'header-fixed-top'      has to be added on #page-container only if the class 'navbar-fixed-top' was added

    'navbar-fixed-bottom'       for a bottom fixed header (fixed main sidebar with scroll will be auto initialized, functionality can be found in js/app.js - handleSidebar()))
        'header-fixed-bottom'   has to be added on #page-container only if the class 'navbar-fixed-bottom' was added
-->
<header class="navbar navbar-inverse navbar-fixed-top">

    <!----------- ADMINISTRADOR ----------->
    <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">
    <!-- Left Header Navigation -->
    <ul class="nav navbar-nav-custom">
        <!-- Main Sidebar Toggle Button -->
        <li id="hamburguer-button">
            <a href="javascript:void(0)" onclick="App.sidebar('toggle-sidebar');this.blur();">
                <i class="fa fa-ellipsis-v fa-fw animation-fadeInRight" id="sidebar-toggle-mini"></i>
                <i class="fa fa-bars fa-fw animation-fadeInRight" id="sidebar-toggle-full"></i>
            </a>
        </li>
        <!-- END Main Sidebar Toggle Button -->

        <!-- Header Link -->
        <!-- <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/dashboard']"><strong>Bienvenid@</strong> al sistema de reservas de actividades</a>

        </li> -->
        <li id="header-icon-mas" class="mobile-hidden">
            <img src="assets/icon-mas.svg" />
        </li>
        <li id="header-icon-calendar" class="mobile-hidden">
            <img src="assets/icon-calendar.svg" />
        </li>
        <li id="header-icon-notification" class="mobile-hidden">
            <img src="assets/icon-notification.svg" />
        </li>
        <!-- <li id="header-search" class="hide">
            <form action="page_ready_search_results.html" method="post" class="navbar-form-custom">
                <div class="display-flex">
                    <input type="text" id="top-search" name="top-search" class="form-control" placeholder="buscar..."><img id="header-icon-lupa" src="assets/icon-lupa.svg" />
                </div>
            </form>
        </li> -->
        <li id="header-logo-tablet-movil">
            <img src="assets/logo.svg" />
        </li>
        <!-- END Header Link -->
    </ul>
    <!-- END Left Header Navigation -->
    </ng-container>

    <!----------- COLABORADOR ----------->

    <ng-container *ngIf="globals.me.id_rol == 4">
    <!-- Left Header Navigation -->
    <ul class="nav navbar-nav-custom">
        <!-- Main Sidebar Toggle Button -->
        <li id="hamburguer-button">
            <a href="javascript:void(0)" onclick="App.sidebar('toggle-sidebar');this.blur();">
                <i class="fa fa-ellipsis-v fa-fw animation-fadeInRight" id="sidebar-toggle-mini"></i>
                <i class="fa fa-bars fa-fw animation-fadeInRight" id="sidebar-toggle-full"></i>
            </a>
        </li>
        <!-- END Main Sidebar Toggle Button -->

        <!-- Header Link -->
        <!-- <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/dashboard']"><strong>Bienvenid@</strong> al sistema de reservas de actividades</a>

        </li> -->
        <!-- <li id="header-search" class="hide">
            <form action="page_ready_search_results.html" method="post" class="navbar-form-custom">
                <div class="display-flex">
                    <input type="text" id="top-search" name="top-search" class="form-control" placeholder="buscar..."><img id="header-icon-lupa" src="assets/icon-lupa.svg" />
                </div>
            </form>
        </li> -->
        <li id="header-logo-tablet-movil">
            <a [routerLink]="['/dashboard']">
                <img src="assets/logo.svg"  />
            </a>
        </li>
        <!-- END Header Link -->
    </ul>
    <!-- END Left Header Navigation -->
    </ng-container>

    

    <!-- Right Header Navigation -->
    <ul class="nav navbar-nav-custom pull-right">
        <!-- Search Form - ->
        <li>
            <form action="page_ready_search_results.html" method="post" class="navbar-form-custom">
                <input type="text" id="top-search" name="top-search" class="form-control" placeholder="Search..">
            </form>
        </li>
        <!- - END Search Form -->
        <ng-container *ngIf="globals && globals.me">
            <ng-container *ngIf="globals.me.id_rol == 0">
                <li class="m-r-30">
                    <a [routerLink]="['/config-app']" style="line-height:45px;">
                        <i class="gi gi-settings"></i> Config. App
                    </a>
                </li>
            </ng-container>
        </ng-container>

        <!-- User Dropdown -->
        <li class="dropdown">
            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" style="line-height:40px;">
                <ng-container *ngIf="globals && globals.me && globals.me.imagen && globals.me.imagen != ''">
                    <img src="{{globals.me.imagen}}" alt="avatar" onerror="this.src = 'assets/icon-user.svg'">
                </ng-container>
                <ng-container *ngIf="!globals || !globals.me || !globals.me.imagen || globals.me.imagen == ''">
                    <img src="assets/icon-user.svg" alt="avatar">
                </ng-container>
                <span *ngIf="globals.me.empresa" class="m-l-10 mobile-hidden-inline">{{globals.me.empresa}}</span>
                <span *ngIf="!globals.me.empresa" class="m-l-10 mobile-hidden-inline">{{globals.me.nombre}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-header">
                    <strong>{{globals.me.rol}}</strong>
                </li>
                <li>
                    <a [routerLink]="['/mi-perfil']" class="cursor">
                        <i class="fa fa-pencil-square fa-fw pull-right"></i>
                        Mi perfil
                    </a>
                </li>
                <li class="divider"><li>
                <li>
                    <a (click)="Logout()" class="cursor">
                        <i class="fa fa-power-off fa-fw pull-right"></i>
                        Cerrar sesión
                    </a>
                </li>
            </ul>
        </li>
        <!-- END User Dropdown -->
    </ul>
    <!-- END Right Header Navigation -->
    
</header>
<!-- Sticky bar bottom -->
<div id="sticky-bar" class="display-flex show-tablet" *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">
    <div id="sticky-button-mas">
        <!-- <img src="assets/icon-mas-sticky.svg"> -->
    </div>
    <div id="sticky-button-qr">
        <img src="assets/icon-qr-sticky.svg" (click)="callCameraQr()">
        <button id="close-lector-qr" style="display: none;" (click)="cerrarLector()">Cerrar lector</button>
    </div>
    <div id="sticky-button-calendar">
        <a href="/dashboard#ancla-calendar"><img src="assets/icon-calendar.svg"></a>
    </div>
    <div id="sticky-button-notification">
        <img src="assets/icon-notification.svg">
    </div>
</div>
    <div id="reader" style="margin-top: 60px;"></div>
<!-- END Sticky bar bottom -->
<app-modal-reserva></app-modal-reserva>


<div class="onboard-modal modal fade animated show-on-load">
    <div class="modal-dialog modal-centered">
        <div class="modal-content text-center">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span class="close-label">Saltar Intro</span>
            <span class="os-icon os-icon-close"></span>
        </button>
        <div class="onboard-slider-container">
            <div class="onboard-slide">
            <div class="onboard-media animate__animated animate__pulse animate__slow animate__infinite">
                <img alt="" src="assets/onboarding/img/emoji-glasses.png" class="emoji" />
            </div>
            <div class="onboard-curve-left">
                <h4 class="onboard-title">
                    Paso 1
                </h4>
                <div class="onboard-text">
                    <img src="assets/onboard-1.jpg" alt="">
                    <p>Cuando se accede al panel, podemos ver de forma rápida la disponibilidad de los días en las cuales en verde es que hay más de un 50% de plazas disponibles, amarillo que quedan menos del 50% y en rojo cuando quedan menos de un 10%</p>
                    
                    <img src="assets/onboard-2.jpg" alt="">
                    <p>Al ir pasando el ratón por encima de cada día, se ve el aforo disponible de ese mismo día</p>
                </div>
            </div>
            </div>
            <div class="onboard-slide">
            <div class="onboard-media animate__animated animate__pulse animate__slow animate__infinite">
                <img alt="" src="assets/onboarding/img/emoji-ok.png" class="emoji" />
            </div>
            <div class="onboard-slice-right">
                <h4 class="onboard-title">
                    Paso 2
                </h4>
                <div class="onboard-text">
                    <img src="assets/onboard-3.jpg" alt="" class="lg mobile-hidden">
                    <img src="assets/onboard-3-movil.jpg" alt="" class="lg desktop-hidden">
                    <p>Si pinchamos encima de un día, se nos abre una ventana para proceder a hacer la reserva en la cual tiene 2 pasos: seleccionar número de entradas e introducir los datos del cliente al hacer la reserva.
                        Aquí seleccionamos el número de los adultos, niños y niños menores haciendo clic en los botones + y  -.
                        Una vez introducido el número de entradas a reservar le damos a siguiente.
                        </p>
                </div>
            </div>
            </div>
            <div class="onboard-slide">
            <div class="onboard-media animate__animated animate__pulse animate__slow animate__infinite">
                <img alt="" src="assets/onboarding/img/emoji-blush.png" class="emoji" />
            </div>
            <div class="onboard-slice-right">
                <h4 class="onboard-title">
                    Paso 3
                </h4>
                <div class="onboard-text">
                    <img src="assets/onboard-4.jpg" alt="" class="lg mobile-hidden">
                    <img src="assets/onboard-4-movil.jpg" alt="" class="lg desktop-hidden">
                    <p>Aquí introducimos los datos del cliente al hacer la reserva y seleccionamos el método de pago que actualmente en esta captura está solo pago en mano. En un futuro puede haber otros tipos de pago.
                        Al darle al pagar y finalizar, se generará la reserva la cuál llegará la verificación al email introducido con el QR de validación de la entrada.
                        </p>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</div>

<!-- Modal reserva desde calendario -->
<div class="modal inmodal fade" id="modal-reservas-hoy" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content modal-lg">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" style="font-size: 35px;"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
                <h3 class="modal-title" style="text-align: center;">Añadir reserva</h3>
            </div>
            <div class="modal-body">
                <div id="paso-1-modal-reservas-hoy" class="row">
                  <div class="col-md-2 col-xs-12"></div>
                  <div class="col-md-8 col-xs-12">
                      <h3 class="title-paso">Paso 1</h3>
                      <div class="form-group row">
                          <div id="content-date-reserva" class="display-flex">
                              <label class="col-lg-4 col-form-label label-modal-title"><i class="icofont-calendar"></i> Fecha seleccionada</label>
                              <div class="col-lg-8">
                                  <input id="date-reservar-modal-reservas-hoy" type="date" class="form-control" maxlength="5" style="width: fit-content;">
                              </div>
                          </div>
                          <div class="margin-top-10 display-flex" id="content-radio-reserva">
                              <label class="col-lg-4 col-xs-4 col-form-label label-modal-title" ><i class="icofont-clock-time"></i> Sesiones</label>
                              <div class="col-lg-4 col-xs-4 display-flex" *ngFor="let sesion of aforoDia">
                                  <input id="{{sesion.id}}" type="radio" class="form-control radios-sesion" value="{{sesion.sesion}}-{{sesion.aforo}}" name="sesion" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{sesion.id}}">{{sesion.sesion}} ({{sesion.aforo}} plazas)</label>
                              </div>
                          </div>
                          <div class="margin-top-10" id="content-radio-personas">
                              <div class="col-lg-12 margin-bottom-10">
                                  <div class="row">
                                      <div class="col-lg-5 col-xs-6">
                                          <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> Adultos</label><br class="hide-movil">
                                          <span class="precio-persona hide-movil">{{precioAdulto}}€</span>
                                      </div>
                                      <div class="col-lg-4 col-xs-3 no-padding-movil">
                                          <div class="display-flex">
                                              <i class="icofont-minus-circle custom-menos disabled-custom-input"></i><input class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.adultos" disabled><i class="icofont-plus-circle custom-mas"></i>
                                          </div>
                                      </div>
                                      <div class="col-lg-3 col-xs-3">
                                          <p id="precio-calulo-adulto" class="precio-calculo">{{precioCalculoAdulto}}€</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-12 margin-bottom-10">
                                  <div class="row">
                                      <div class="col-lg-5 col-xs-6">
                                          <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> Niños <span>(de 3 a 12 años)</span></label><br class="hide-movil">
                                          <span class="precio-persona hide-movil">{{precioNinio}}€</span>
                                      </div>
                                      <div class="col-lg-4 col-xs-3 no-padding-movil">
                                          <div class="display-flex">
                                              <i class="icofont-minus-circle custom-menos disabled-custom-input"></i><input class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.ninios" disabled><i class="icofont-plus-circle custom-mas"></i>
                                          </div>
                                      </div>
                                      <div class="col-lg-3 col-xs-3">
                                          <p id="precio-calcul-ninio" class="precio-calculo">{{precioCalculoNinio}}€</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-12 margin-bottom-10">
                                  <div class="row">
                                      <div class="col-lg-5 col-xs-6">
                                          <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> Niños <span>(menores de 3 años)</span></label><br class="hide-movil">
                                          <span class="precio-persona hide-movil">Gratis</span>
                                      </div>
                                      <div class="col-lg-4 col-xs-3 no-padding-movil">
                                          <div class="display-flex">
                                              <i class="icofont-minus-circle custom-menos disabled-custom-input"></i><input class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.niniosGratis" disabled><i class="icofont-plus-circle custom-mas"></i>
                                          </div>
                                      </div>
                                      <div class="col-lg-3 col-xs-3">
                                          <p class="precio-calculo">0€</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-12 margin-bottom-10">
                                  <div class="row">
                                      <div class="col-lg-2 col-xs-2">
                                      </div>
                                      <div class="col-lg-2 col-xs-2">
                                      </div>
                                      <div class="col-lg-8 col-xs-8">
                                          <p class="precio-calculo-total">Total: {{dataReserva.precio}}€</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-2 col-xs-12"></div>
                  <div class="modal-footer col-md-12 col-xs-12">
                      <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                      <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="dataReserva.precio > 0" (click)="SiguienteModal()">Siguiente</button>
                  </div>
                </div>  
                <div id="paso-2-modal-reservas-hoy" class="row" style="display: none;">
                  <div class="col-md-2 col-xs-12"></div>
                  <div class="col-md-8 col-xs-12">
                      <h3 class="title-paso">Paso 2</h3>
                      <div class="row">
                          <div class="col-lg-8 col-xs-12">
                              <h4 *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">¿Quién realiza la reserva?</h4>
                              <div class="form-group row" *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">
                                  <div class="col-lg-12 col-xs-12">
                                      <select id="select-colaboradores-reserva-admin-hoy" (change)="SelectReservaProveedor($event)" [value]='admin'>
                                          <option value="admin">Administrador</option>
                                          <option *ngFor="let colaboradorReserva of rankingColaboradores" [value]="colaboradorReserva.id">{{colaboradorReserva.empresa}}</option>
                                      </select>
                                  </div>
                              </div>
                              <h4>Datos</h4>
                              <div class="form-group row">
                                  <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                      <input type="text" placeholder="Nombre*" [(ngModel)]="dataReserva.nombre">
                                  </div>
                                  <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                      <input type="text" placeholder="Apellidos*" [(ngModel)]="dataReserva.apellidos">
                                  </div>
                                  <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                      <input type="phone" placeholder="Teléfono*" [(ngModel)]="dataReserva.telefono">
                                  </div>
                                  <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                      <input type="mail" placeholder="Email*" [(ngModel)]="dataReserva.email">
                                  </div>
                                  <!-- <div class="col-lg-4 col-xs-12 margin-bottom-10">
                                      <input id="pago-tarjeta" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-tarjeta">Tarjeta</label>
                                  </div>
                                  <div class="col-lg-4 col-xs-12 margin-bottom-10">
                                      <input id="pago-paypal" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-paypal">Paypal</label>
                                  </div> -->
                                  <div class="col-lg-6 col-xs-12 margin-bottom-10">
                                      <input id="pago-en-mano" type="radio" name="pago" checked value="Pago en mano"><label style="margin-left: 10px;" for="pago-en-mano">Pago en mano</label>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-xs-12">
                              <h4>Resumen</h4>
                              <div class="row">
                                  <div class="col-lg-12 col-xs-12">
                                      <p class="no-margin-bottom">Fecha: <span>{{dataReserva.fecha}}</span></p>
                                  </div>
                                  <div class="col-lg-12 col-xs-12">
                                      <p class="no-margin-bottom">Sesión: <span>{{dataReserva.sesion}}</span></p>
                                  </div>
                                  <div class="col-lg-12 col-xs-12">
                                      <p class="no-margin-bottom">Adultos: <span>{{dataReserva.adultos}}</span></p>
                                  </div>
                                  <div class="col-lg-12 col-xs-12">
                                      <p class="no-margin-bottom">Niños de 3 a 12 años: <span>{{dataReserva.ninios}}</span></p>
                                  </div>
                                  <div class="col-lg-12 col-xs-12">
                                      <p class="no-margin-bottom">Niños menores de 3 años: <span>{{dataReserva.niniosGratis}}</span></p>
                                  </div>
                                  <div class="col-lg-12 col-xs-12">
                                      <p class="no-margin-bottom">Precio: <span>{{dataReserva.precio}}</span></p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-2 col-xs-12"></div>
                  <div class="modal-footer col-md-12 col-xs-12">
                      <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                      <button type="button" class="btn btn-white" id="btn-anterior" (click)="AnteriorModal()">Anterior</button>
                      <button type="button" class="btn btn-primary" id="btn-guardar-reserva" (click)="ComprobarGuardarReserva()">Pagar y finalizar</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>