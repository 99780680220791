<!-- Global site tag (gtag.js) - Google Ads: 10882867819 -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-10882867819"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-10882867819');
</script>

<div id="content-iframe-frontend">
    <!-- <div class="modal inmodal fade" id="modal-reservar-calendar" tabindex="-1" role="dialog"> -->
    <div id="modal-reservar-calendar">
        <div class="modal-dialog">
            <div class="modal-content modal-lg">
            <div class="modal-header hide">
                <button type="button" class="close" data-dismiss="modal" style="font-size: 35px;"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
                <h3 class="modal-title" style="text-align: center;">Añadir reserva</h3>
            </div>
            <div>
              <div id="content-paso-1" class="row modal-body">
                <div class="col-md-12 col-xs-12">
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" ><i class="fa fa-calendar"></i> {{L('Selecciona fecha')}}</label>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                    <div class="row display-flex width-fit-auto hide-movil">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso active">1. {{L('Fecha y entradas')}}</h3>
                        <h3 class="title-paso">2. {{L('Tus datos')}}</h3>
                        <h3 class="title-paso">3. {{L('Reserva completada')}}</h3>
                    </div>
                    <div id="content-calendar-data" class="row">
                        <div class="col-lg-5 col-sm-5 col-xs-12">
                            <div id="calendar" class="calendario calendario-dashboard calendario-front"></div>
                            <div id="content-legend" style="display: flex; margin-top: 10px;    width: 370px;">
                                <div class="legend" style="width: 100px; display: flex;">
                                    <div style="width: 10px; background-color: #5da84e; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                                    <div style="width: 85%; margin-left: 5%;color: white;line-height: 10px;">Disponibles</div>
                                </div>
                                <div class="legend" style="width: 160px; display: flex;">
                                    <div style="width: 10px; background-color: #e5b437; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                                    <div style="width: 85%; margin-left: 5%;color: white;line-height: 10px;">Poca disponibilidad</div>
                                </div>
                                <div class="legend" style="width: 100px; display: flex;">
                                    <div style="width: 10px; background-color: #a30404; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                                    <div style="width: 85%; margin-left: 5%;color: white;line-height: 10px;">Agotadas</div>
                                </div>
                            </div>
                        </div>
                        <div id="content-entradas" class="col-lg-7 col-sm-7 col-xs-12">
                            <div class="form-group row">
                                <div id="content-date-reserva" class="display-flex hide">
                                    <label class="col-lg-4 col-form-label label-modal-title"><i class="icofont-calendar"></i> Fecha seleccionada</label>
                                    <div class="col-lg-8">
                                        <input id="date-reservar" type="date" class="form-control" maxlength="5" style="width: fit-content;" disabled>
                                    </div>
                                </div>
                                <div class="margin-top-10 display-flex" id="content-radio-reserva">
                                    <label class="col-lg-3 col-sm-3 col-form-label label-modal-title hide-tablet-desktop" ><i class="icofont-ui-tag"></i> Elige una butaca</label>
                                    <label class="col-lg-3 col-sm-3 col-form-label label-modal-title hide-movil" ><i class="icofont-ui-tag"></i> Butaca</label>
                                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                                    <ng-container *ngIf="aforoDia.length != 0">
                                        <div class="col-lg-5 col-sm-5 col-xs-4 display-flex" *ngFor="let actividad of actividades">
                                            <input id="{{actividad.id}}" type="radio" class="form-control radios-butacas" value="{{actividad.id}}" name="butaca" style="width: fit-content; height: fit-content; margin-right: 10px;" (click)="setButaca(actividad.id)"><label for="{{actividad.id}}" *ngIf="actividad.id == 5">Butaca normal</label><label class="tooltip" for="{{actividad.id}}" *ngIf="actividad.id == 6">Butaca VIP <i class="fa fa-info-circle"></i>
                                                    <span class="tooltiptext">PREMIUM<br>
                                                        Butaca Premium + bebida.<br>
                                                        Incluye una Botella vino de Naranja.</span>
                                            </label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="aforoDia.length == 0">
                                        <div class="content-radio-sesion display-flex aviso-sesiones">
                                            *Seleccione un día para elegir butaca
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="margin-top-10 display-flex" id="content-radio-reserva">
                                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop" ><i class="icofont-clock-time"></i> Elige una sesión</label>
                                    <label class="col-lg-4 col-form-label label-modal-title hide-movil" ><i class="icofont-clock-time"></i> {{L('Sesiones')}}</label>
                                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                                    <ng-container *ngIf="aforoDia.length == 0">
                                        <div class="content-radio-sesion display-flex aviso-sesiones">
                                            {{L('*Seleccione un día para ver las sesiones')}}
                                        </div>
                                    </ng-container>
                                    <div class="content-radio-sesion display-flex" *ngFor="let sesion of aforoDia">
                                        <input id="{{sesion.id}}" type="radio" class="form-control radios-sesion" value="{{sesion.sesion}}-{{sesion.aforo}}" (click)="setSesion(sesion.sesion+'-'+sesion.aforo)" name="sesion" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{sesion.id}}">{{sesion.sesion}}h <span *ngIf="sesion.aforo <= 10">({{sesion.aforo}} plazas)</span></label>
                                    </div>
                                </div>
                                <div class="margin-top-10" id="content-radio-personas">
                                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop" ><i class="fa fa-ticket"></i> Entradas</label>
                                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                                    <div class="col-lg-12 margin-bottom-10">
                                        <div class="row">
                                            <div class="col-lg-7 col-xs-6">
                                                <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{L('Adultos')}} <span class="precio-persona">{{precioAdulto}} €</span></label><br>
                                                
                                            </div>
                                            <div class="col-lg-3 col-xs-3 padding-left-0-movil">
                                                <div class="display-flex">
                                                    <i id="custom-menos-adulto" class="icofont-minus-circle custom-menos disabled-custom-input"></i><input id="personas-adulto" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.adultos" disabled><i id="custom-mas-adulto" class="icofont-plus-circle custom-mas"></i>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xs-3">
                                                <p id="precio-calulo-adulto" class="precio-calculo">{{precioCalculoAdulto}} €</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 margin-bottom-10">
                                        <div class="row">
                                            <div class="col-lg-7 col-xs-6">
                                                <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{L('Niños')}} <span class="precio-persona">{{precioNinio}} €</span><br/><span> {{L('(de 3 a 12 años)')}} </span></label><br>
                                                
                                            </div>
                                            <div class="col-lg-3 col-xs-3 padding-left-0-movil">
                                                <div class="display-flex">
                                                    <i id="custom-menos-ninio" class="icofont-minus-circle custom-menos disabled-custom-input"></i><input id="personas-ninios" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.ninios" disabled><i id="custom-mas-ninio" class="icofont-plus-circle custom-mas"></i>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xs-3">
                                                <p id="precio-calcul-ninio" class="precio-calculo">{{precioCalculoNinio}} €</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 margin-bottom-10 margin-bottom-0-movil">
                                        <div class="row">
                                            <div class="col-lg-7 col-xs-6">
                                                <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{L('Niños gratis')}} <br/><span>{{L('(menores de 3 años)')}} </span> </label><br>
                                                
                                            </div>
                                            <div class="col-lg-3 col-xs-3 padding-left-0-movil">
                                                <div class="display-flex">
                                                    <i id="custom-menos-ninio-gratis" class="icofont-minus-circle custom-menos disabled-custom-input"></i><input id="personas-ninios-gratis" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.niniosGratis" disabled><i id="custom-mas-ninio-gratis" class="icofont-plus-circle custom-mas"></i>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xs-3">
                                                <p class="precio-calculo">0 €</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 margin-bottom-10">
                                        <div class="row">
                                            <div class="col-lg-2 col-xs-2">
                                            </div>
                                            <div class="col-lg-2 col-xs-2">
                                            </div>
                                            <div class="col-lg-8 col-xs-8">
                                                <p class="precio-calculo-total">{{L('Total')}}: {{dataReserva.precio}} €</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70 padding-top-0">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="dataReserva.precio > 0" (click)="SiguienteModal()">{{L('Siguiente')}}</button>
                </div>
              </div>  
              <div id="content-paso-2" class="row modal-body" style="display: none;">
                <div class="col-md-12 col-xs-12">
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" ><i class="fa fa-ticket"></i> {{L('Resumen de tus entradas')}}</label>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                    <div class="row display-flex width-fit-auto hide-movil">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso anterior">1. {{L('Fecha y entradas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso active">2. {{L('Tus datos')}}</h3>
                        <h3 class="title-paso">3. {{L('Reserva completada')}}</h3>
                    </div>
                    <div id="content-resumen-form" class="row">
                        <div id="content-resumen" class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                            <h4 class="hide-movil">{{L('Resumen')}}</h4>
                            <div class="row">
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{L('Fecha')}}: <span>{{dataReserva.fecha}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{L('Sesión')}}: <span>{{dataReserva.sesion}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{L('Adultos')}}: <span>{{dataReserva.adultos}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{L('Niños')}} <span style="font-size: 12px;">{{L('de 3 a 12 años')}}</span>: <span>{{dataReserva.ninios}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{L('Niños')}} <span style="font-size: 12px;">{{L('menores de 3 años')}}</span>: <span>{{dataReserva.niniosGratis}}</span></p>
                                </div>
                                <ng-container *ngIf="!dataReserva.cupon">
                                    <div class="col-lg-12 col-xs-12">
                                        <p class="no-margin-bottom">{{L('Precio')}}: <span>{{dataReserva.precio}}€</span></p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="dataReserva.cupon">
                                    <div class="col-lg-12 col-xs-12">
                                        <p class="no-margin-bottom">{{L('Precio')}}: <span>{{dataReserva.precio_original}}€</span></p>
                                    </div>
                                    <div class="col-lg-12 col-xs-12">
                                        <p class="no-margin-bottom">{{L('Descuento')}}: -<span>{{dataReserva.cupon.descuento}}</span></p>
                                        <p class="no-margin-bottom">{{L('Total')}}: <span>{{globals.FloatES(dataReserva.total)}}€</span></p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div id="content-datos" class="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                            <h4 class="hide-movil">{{L('Datos_cliente')}}</h4>
                            <h4 class="hide-tablet-desktop" style="margin-bottom: 5px;"><i class="fa fa-pencil"></i> {{L('Datos_rellena_cliente')}}</h4>
                            <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">
                            <div class="form-group row m-b-5">
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label>{{L('Nombre')}}*</label>
                                    <input type="text" placeholder="{{L('Nombre')}}" [(ngModel)]="dataReserva.nombre">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label>{{L('Apellidos')}}*</label>
                                    <input type="text" placeholder="{{L('Apellidos')}}" [(ngModel)]="dataReserva.apellidos">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label>{{L('Teléfono')}}*</label>
                                    <input type="phone" placeholder="{{L('Teléfono')}}" [(ngModel)]="dataReserva.telefono">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label>{{L('Email')}}*</label>
                                    <input type="mail" placeholder="{{L('Email')}}" [(ngModel)]="dataReserva.email">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label>{{L('Procedencia')}}</label>
                                    <input type="text" placeholder="{{L('Procedencia')}}" [(ngModel)]="dataReserva.cli_localidad">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10 input-cupon">
                                    <label>{{L('Cupón de descuento')}}</label>
                                    <input type="text" placeholder="{{L('Cupón de descuento')}}" [(ngModel)]="dataReserva.codigo_cupon" (input)="ComprobarCupon()"
                                        [disabled]="dataReserva.email && dataReserva.email.trim() != '' ? false : true">
                                    <i class="fa fa-check text-success icon-cupon" *ngIf="dataReserva.mostracuponok"></i>
                                    <i class="fa fa-times text-danger icon-cupon" *ngIf="dataReserva.mostracuponko"></i>
                                </div>
                                <!-- <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                                    <input id="pago-tarjeta" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-tarjeta">Tarjeta</label>
                                </div>
                                <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                                    <input id="pago-paypal" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-paypal">Paypal</label>
                                </div> -->
                            </div>
                            <div class="clearfix"></div>
                            <div class="">
                                <label class="col-form-label label-modal-title hide-tablet-desktop label-calendar-title" style="margin-top: 0px;"><i class="icofont-credit-card"></i> Tipos de pago</label>
                                <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">
                                <div *ngFor="let item of formas_pago" class="inline-block m-r-20 formas-pago">
                                    <input id="{{item.slug}}" class="fit-content" type="radio" name="pago" value="{{item.forma_pago}}" (click)="SetFormaPago(item)">
                                    <label style="margin-left: 10px;" for="{{item.slug}}" (click)="SetFormaPago(item)">{{item.forma_pago}} 
                                        <i class="icofont-paypal-alt" *ngIf="item.forma_pago == 'Paypal'"></i>
                                        <i class="icofont-credit-card" *ngIf="item.forma_pago == 'Tarjeta bancaria' || item.forma_pago == 'Tarjeta de crédito'"></i>
                                        <i class="icofont-stripe-alt" *ngIf="item.forma_pago == 'Stripe'"></i>
                                    </label>
                                </div>
                            </div>
                            <div id="stripe-form" class="m-t-10 m-b-10" style="display:none;">
                                <div id="card-element"></div>
                                <div id="card-errors" role="alert"></div>
                            </div>
                            <div id="content-politicas-privacidad" style="display: flex;">
                                <input id="check-politicas-privacidad" type="checkbox" style="width: fit-content; margin-right: 5px;"/><label for="check-pliticas-privacidad">{{L('Acepto las')}} <a href="https://tablaoflamencoandalusi.com/politica-privacidad/" target="_blank" style="font-weight: 700;">{{L('política de privacidad')}}</a></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-white" id="btn-anterior" (click)="AnteriorModal()">{{L('Anterior')}}</button>
                    <button type="button" class="btn btn-primary" id="btn-guardar-reserva" (click)="ComprobarGuardarReserva()">{{L('Pagar y finalizar')}}</button>
                </div>
              </div>
              <div id="content-paso-3" class="row modal-body" style="display: none;">
                <div class="col-md-12 col-xs-12">
                    <div class="row display-flex width-fit-auto">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso anterior">1. {{L('Fecha y entradas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso anterior">2. {{L('Tus datos')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso active">3. {{L('Reserva completada')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                    </div>
                    <ng-container *ngIf="!forma_pago_seleccionada || forma_pago_seleccionada.slug != 'stripe'">
                        <div id="content-resumen-form" class="row" style="color: white; text-align: center;">
                            {{L('Si ya has terminado puedes volver a realizar otra reserva')}}.
                        </div>
                    </ng-container>
                    <ng-container *ngIf="forma_pago_seleccionada && forma_pago_seleccionada.slug == 'stripe'">
                        <div id="content-resumen-form" class="row" style="color: white; text-align: center;">
                            <h1>{{L('¡Gracias por tu reserva!')}}</h1>
                            <p>{{L('A continuación te mostramos los detalles de tu reserva')}}:</p>
                            <div class="row row-small datos-reserva" *ngIf="dataReserva && dataReserva.id">
                              <div class="col-xs-6">
                                <ul class="list-group">
                                    <li class="list-group-item active">{{L('Tus datos')}}</li>
                                    <li class="list-group-item datos">
                                      <div>{{dataReserva.cli_nombre}} {{dataReserva.cli_apellidos}}</div>
                                      <div *ngIf="dataReserva.cli_empresa">{{dataReserva.cli_empresa}}<span *ngIf="dataReserva.cli_nifcif">, {{dataReserva.cli_nifcif}}</span></div>
                                      <div>{{dataReserva.cli_direccion}}<span *ngIf="dataReserva.cli_codpostal">, {{dataReserva.cli_codpostal}}</span></div>
                                      <div>{{dataReserva.cli_localidad}}<span *ngIf="dataReserva.cli_pais">, {{dataReserva.cli_pais}}</span></div>
                                      <div *ngIf="dataReserva.cli_email"><i class="fa fa-envelope-o"></i> {{dataReserva.cli_email}}</div>
                                      <div *ngIf="dataReserva.cli_telefono"><i class="fa fa-phone"></i> {{dataReserva.cli_telefono}}</div>
                                      <div *ngIf="dataReserva.cli_notas"><br>{{dataReserva.cli_notas}}</div>
                                    </li>
                                </ul>
                                <ul class="list-group">
                                    <li class="list-group-item active">{{L('Modalidad de pago')}}</li>
                                    <li class="list-group-item datos">
                                      <div class="dato-destacado">{{dataReserva.cli_tipo_pago}}</div>
                                    </li>
                                </ul>
                              </div>
                              <div class="col-xs-6">
                                <ul class="list-group">
                                    <li class="list-group-item active">{{L('Datos de la reserva')}}</li>
                                    <li class="list-group-item datos">
                                      <div class="dato-destacado">#{{dataReserva.referencia}}</div>
                                      <div class="dato-destacado">{{dataReserva.evento}}</div>
                                      <div>Fecha del evento: {{dataReserva.fecha_format}}</div>
                                      <div>Sesión del evento: {{dataReserva.sesion}}</div>
                                      <div>Adultos: {{dataReserva.adultos}}</div>
                                      <div>Niños: {{dataReserva.ninos}}</div>
                                      <div>Niños gratis: {{dataReserva.ninos_gratis}}</div>
                                    </li>
                                    <li class="list-group-item active font-bold font-20">
                                      Total
                                      <div class="pull-right">{{dataReserva.total_format}} €</div>
                                    </li>
                                </ul>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-primary" id="btn-guardar-reserva" onclick="window.location.reload()">{{L('Realizar otra reserva')}}</button>
                </div>
              </div>
              <div id="content-paso-error" class="row modal-body" style="display: none;">
                <div class="col-md-12 col-xs-12">
                    <div class="row display-flex width-fit-auto">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso anterior">1. {{L('Fecha y entradas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso anterior">2. {{L('Tus datos')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso active error">3. {{L('Reserva completada')}} <i class="fa fa-times" aria-hidden="true"></i></h3>
                    </div>
                    <div id="content-resumen-form" class="row">
                    </div>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-white" id="btn-anterior" (click)="VolverPaso2()">{{L('Anterior')}}</button>
                </div>
              </div>
            </div>
            </div>
        </div>
    </div>
</div>
<!-- Event snippet for Compra conversion page
In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
<script>
function gtag_report_conversion(url) {
    var callback = function () {
    if (typeof(url) != 'undefined') {
        window.location = url;
    }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-10882867819/aHo3CIX897IDEOvErcUo',
        'value': 1.0,
        'currency': 'EUR',
        'transaction_id': '',
        'event_callback': callback
    });
    return false;
}
$(document).ready(()=>{
    $('#btn-guardar-reserva').click(()=>{
        gtag_report_conversion(url);
    });
});
</script>