import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html'
})
export class ReservasComponent implements OnInit {
  public misreservas:boolean = (window.location.pathname.indexOf('mis-reservas') != -1);
  public reservas = [];
  public reservas_calendario = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
    cupon: ''
  };
  public params_calendario:any = {};
  public sesiones:any = [];
  public idCalendario:any;
  public aforo = [];
  public mesesAforo:any = [];
  public cupones:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    if (!this.misreservas) $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    $.modalreparaciondelete = item => {
      this.Borrar(item);
    };
    
    this.idCalendario = 5;
    this.LoadAforo();
    let fecha = moment(new Date()).format('YYYY-MM-DD');
    this.CargarReservasFecha(fecha);
    this.CargarCupones();
  }

  GetTabActive() {
    return $('.nav-principal li.active a').attr('href');
  }

  CargarCupones() {
    this.globals.Api('/eve-cupones').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.cupones = data;
    });
  }

  Actualizar() {
    this.CargarGrid();
    this.CargarReservasFecha(this.params_calendario.fecha);
  }
  CargarGrid(paginationload:boolean = true) {
    if(this.globals.me.id_rol == 4){
      this.params.id_colaborador = this.globals.me.id;
    }
    if (this.globals.GetData()) this.reservas = this.globals.GetData();
    this.globals.Api('/eve-reservas', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.reservas = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.reservas = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/eve-eventos/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.params_calendario.filtro = this.params.filtro;
    this.CargarGrid();
    this.CargarReservasFecha(this.params_calendario.fecha);
  }
  Nuevo() {
    this.globals.passData = '';
    // this.ngZone.run(() => this.router.navigateByUrl('/reservas/add')).then();
    if ($.NuevaReservaGeneral) $.NuevaReservaGeneral(this.params_calendario.fecha);
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/reservas/edit')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-del', {id: item.id,fecha: item.fecha, id_evento: item.id_evento, adultos: item.adultos, ninios: item.ninos, niniosGratis: item.ninos_gratis, sesion: item.sesion, fecha_bd: item.fecha_bd}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.reservas.splice(this.reservas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  VistaPrevia(item:any) {
    $.modalreservadata = item;
    if ($.modalreservashow) $.modalreservashow();
  }

  
  CalcularPersonas(adultos,ninos,ninosGratis){
    return (adultos+ninos+ninosGratis);
  }
  EnviarEmail(item:any, event:any) {
    event.stopPropagation();
    if (!item.cli_email || item.cli_email == '') {
      toastr.info('No se ha guardado email', 'Sin email');
      return;
    }
    swal({
      title: "¿Enviar un correo de confirmación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-reenviar-email-confirmacion', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success('Email enviado correctamente', 'Email enviado');
      });
    });
  }
  EnviarWhatsapp(item:any, event:any) {
    event.stopPropagation();
    if (!item.cli_telefono || item.cli_telefono == '') {
      toastr.info('No se ha guardado número de teléfono', 'Sin número');
      return;
    }
    let fecha = moment(item.alta).locale('es').format('dddd D MMMM');
    let enlace = document.createElement('a');
    let cliente = item.cli_nombre;
    enlace.href = `https://api.whatsapp.com/send?phone=${item.cli_telefono}`;
    enlace.target = '_blank';
    enlace.click();
  }

  LoadAforo(){
    this.globals.Api('/eve-eventos',{'id': this.idCalendario}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.sesiones = data[0].sesiones.split(',');
      this.sesiones.pop();
    });
    this.globals.Api('/eve-eventos-aforo',{'id': this.idCalendario, 'oculto': true}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.aforo = data;
      var month = '';
      this.aforo.forEach(item =>{
        item.percentage = (100 * item.aforo_restante) / item.aforo_total;
        var dt = new Date(item.fecha);
        var monthYear:any = {};
        item.mes = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.month = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.year = item.anio = dt.getFullYear();
        if(month != monthYear.month){
          month = monthYear.month;
          this.mesesAforo.push(monthYear);
        }
      });
    });
  }

  CargarReservasFecha(fecha:any = null) {
    $('.btn-sesiones .btn').removeClass('active');
    $('.btn-sesiones .btn-todas').addClass('active');
    if (this.globals.me.id_rol == 4) this.params_calendario.id_colaborador = this.globals.me.id;
    this.params_calendario.fecha = fecha;
    this.params_calendario.fecha_format = 'Día '+moment(fecha).format('DD/MM/YYYY');
    if (moment().format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY')) this.params_calendario.fecha_format = 'Hoy';
    if (moment().add(1, 'days').format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY')) this.params_calendario.fecha_format = 'Mañana';
    this.params_calendario.groupBy = 'fecha';
    if (fecha) this.params_calendario.groupBy = '';
    if (this.globals.GetData('reservas-calendario')) this.reservas_calendario = this.globals.GetData('reservas-calendario');
    this.params_calendario.order = 'fecha, sesion';
    this.globals.Api('/eve-reservas', this.params_calendario).subscribe(data => {
      if (!data || !data.length) {
        this.reservas_calendario = [];
        globalThis.reservas_calendario_original = [];
        this.globals.SaveData([]);
        this.CargarCalendario();
        return;
      }
      this.sesiones = data[0].sesiones.split(',');
      this.globals.SaveData(data, 'reservas-calendario');
      this.reservas_calendario = data.filter(el => el.id_estado <= 3);
      globalThis.reservas_calendario_original = data;
      for (let item of this.reservas_calendario) {
        item.tipo_slug = this.globals.CleanedString(item.tipo);
      }
      this.appref.tick();
      this.CargarCalendario();
    });
  }
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 425;
    if($(window).width() <= 1366 && $(window).width() > 1025){
      heightCalendar = 380;
    }
    if($(window).width() <= 500){
      heightCalendar = 340;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        viewRender: (view, element) => {
          this.CargarEventosCalendario();
          $('.calendario-listado-reservas .fc-day-top').click(function(){
            $('.calendario-listado-reservas .fc-day-top').css('background-color','unset');
            $('.calendario-listado-reservas .fc-day-top').css('color','unset');
            $(this).css('background-color','#DB0033');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
        },
        dayClick: (date, allDay, jsEvent, view)=>{
          var tempDate = new Date(date).toDateString();
          let fecha = moment(tempDate).format('YYYY-MM-DD');
          this.CargarReservasFecha(fecha);
        }
    });
  }
  CargarEventosCalendario() {
    $('#calendar').fullCalendar('removeEvents');
    var calendar = $('#calendar').fullCalendar('getCalendar');
    var view = calendar.view;
    var start = view.start._d;
    var end = view.end._d;
    let params = {
      fecha_inicio: moment(start).format('YYYY-MM-DD'),
      fecha_fin: moment(end).format('YYYY-MM-DD')
    };
    this.globals.Api('/eve-reservas', params).subscribe(data => {
      if (!data || !data.length) return;
      let mismaFecha = false;
      let fechaTemp = '';
      let sumPorcentajeMismaFecha = 0;
      let events = [];
      if (this.globals.GetData('eventos-calendario-reservas')) {
        events = this.globals.GetData('eventos-calendario-reservas');
        $('#calendar').fullCalendar('renderEvents', events, true);
      }
      for (let item of data) {
        if(fechaTemp != item.fecha){
          fechaTemp = item.fecha;
          //sumPorcentajeMismaFecha = item.percentage;
          mismaFecha = false;
        }else{
          mismaFecha = true;
          //sumPorcentajeMismaFecha = (sumPorcentajeMismaFecha + item.percentage) / 2;
        }
        let start = new Date(item.fecha);
        let end = moment(start);
        let color = 'transparent';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'block';
        let className = 'day-green';
        if(!mismaFecha){
          //className = 'hide';
        }
        let event = {
          id: item.id,
          className: className,
          //title: `${item.aforo_restante}/${item.aforo_total}`,
          title: '',
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"> <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          color: color,
          //color: 'transparent',
          textColor: textColor,
          //textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        events.push(event);
        // $('#calendar').fullCalendar('renderEvent', event, true);
      }
      $('#calendar').fullCalendar('renderEvents', events, true);
      // $('#calendar').fullCalendar('refetchEvents');
    });
    
    this.appref.tick();
  }
  mostrarTodasSesiones(){
    this.reservas_calendario = globalThis.reservas_calendario_original.filter(el => el.id_estado <= 3);
    $('.btn-sesiones .btn').removeClass('active');
    $('.btn-sesiones .btn-todas').addClass('active');
  }
  mostrarSesion(sesion:any, index:any) {
    this.reservas_calendario = globalThis.reservas_calendario_original.filter(el => el.id_estado <= 3 && el.sesion == sesion);
    $('.btn-sesiones .btn').removeClass('active');
    $('.btn-sesiones .btn.sesion-'+index).addClass('active');
  }
  GetTotalPersonas() {
    let total:number = 0;
    if (!globalThis.reservas_calendario_original || !globalThis.reservas_calendario_original.length) return 0;
    for (let item of globalThis.reservas_calendario_original.filter(el => el.id_estado <= 3)) {total += item.adultos+item.ninos+item.ninos_gratis;}
    return total;
  }
  GetTotalPersonasSesion(sesion:any) {
    let total:number = 0;
    if (!globalThis.reservas_calendario_original || !globalThis.reservas_calendario_original.length) return 0;
    for (let item of globalThis.reservas_calendario_original.filter(el => el.id_estado <= 3 && el.sesion == sesion)) {total += item.adultos+item.ninos+item.ninos_gratis;}
    return total;
  }
  GetReservasCanceladas() {
    if (!globalThis.reservas_calendario_original || !globalThis.reservas_calendario_original.length) return [];
    return globalThis.reservas_calendario_original.filter(el => el.id_estado > 3);
  }
}
