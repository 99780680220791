import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {
  public clientes:boolean = (window.location.pathname.indexOf('clientes') != -1);
  public colaboradores:boolean = (window.location.pathname.indexOf('colaboradores') != -1);
  public title:string = 'Usuarios';
  public usuarios = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    if (this.clientes) this.title = 'Clientes';
    if (this.colaboradores) this.title = 'Colaboradores';
  }

  CargarGrid(paginationload:boolean = true) {
    this.params.id_rol = '0,1,2';
    if (this.clientes) this.params.id_rol = 3;
    if (this.colaboradores) this.params.id_rol = 4;
    if (this.globals.GetData()) this.usuarios = this.globals.GetData();
    this.globals.Api('/usuarios', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.usuarios = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.usuarios = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/usuarios/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Filtrar(filtro:string) {
    $("#txtFiltroFecha input").val('');
    this.params.fechaalta = null;
    this.params.fechapagado = null;
    this.params.fechacaducidad = null;
    switch(filtro) {
      case 'proximocaducar': {
        this.params.tipofiltro = filtro;
        this.params.proximocaducar = true;
        this.params.caducado = null;
        this.Buscar();
      } break;
      case 'caducado': {
        this.params.tipofiltro = filtro;
        this.params.proximocaducar = null;
        this.params.caducado = true;
        this.Buscar();
      } break;
      case 'todo': {
        this.params.tipofiltro = null;
        this.params.proximocaducar = null;
        this.params.caducado = null;
        this.Buscar();
      } break;
    };
  }
  FiltrarFecha(filtro:string) {
    this.params.tipofiltrofecha = filtro;
    let fecha = moment($("#txtFiltroFecha").datepicker('getDate'), 'YYYY-MM-DD');
    switch(filtro) {
      case 'alta': {
        this.params.fechaalta = fecha;
        this.params.fechapagado = null;
        this.params.fechacaducidad = null;
        this.Buscar();
      } break;
      case 'pagado': {
        this.params.fechaalta = null;
        this.params.fechapagado = fecha;
        this.params.fechacaducidad = null;
        this.Buscar();
      } break;
      case 'caducidad': {
        this.params.fechaalta = null;
        this.params.fechapagado = null;
        this.params.fechacaducidad = fecha;
        this.Buscar();
      } break;
    };
  }
  Nuevo() {
    this.globals.passData = '';
    if (this.clientes) {
      this.ngZone.run(() => this.router.navigateByUrl('/clientes/add')).then();
      return;
    }
    if (this.colaboradores) {
      this.ngZone.run(() => this.router.navigateByUrl('/colaboradores/add')).then();
      return;
    }
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    if (this.clientes) {
      this.ngZone.run(() => this.router.navigateByUrl('/clientes/edit')).then();
      return;
    }
    if (this.colaboradores) {
      this.ngZone.run(() => this.router.navigateByUrl('/colaboradores/edit')).then();
      return;
    }
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios/edit')).then();
  }
  CambiarAcceso(item:any) {
    this.globals.Api('/editar-acceso-usuario', {id: item.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      item.acceso = data.value;
      this.appref.tick();
    });
  }
  CambiarDisponibilidad(item:any) {
    this.globals.Api('/editar-disponible-usuario', {id: item.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      item.tecnico_disponible = data.value;
      this.appref.tick();
    });
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/usuarios-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.usuarios.splice(this.usuarios.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
